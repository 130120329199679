import axios from "axios";
const { REACT_APP_API_CARBON_API } = process.env;

export const FnAuthenticatedRequest = async (
  requestType,
  endpoint,
  headers,
  payload = {}
) => {
  try {
    let response;
    if (requestType === "GET") {
      response = await axios.get(`${REACT_APP_API_CARBON_API}/${endpoint}`, {
        params: convertKeysToSnakeCase(payload),
        headers: {
          ...headers,
          JWTToken: "Bearer " + headers.JWTToken,
          appCode: "carbon-bo",
          appVersion: "1.0",
        },
      });
    } else if (requestType === "POST") {
      response = await axios.post(
        `${REACT_APP_API_CARBON_API}/${endpoint}`,
        convertKeysToSnakeCase(payload),
        {
          headers: {
            ...headers,
            JWTToken: "Bearer " + headers.JWTToken,
            appCode: "carbon-bo",
            appVersion: "1.0",
          },
        }
      );
    } else {
      throw new Error(
        "Unsupported request type. Only GET and POST are supported."
      );
    }
    return convertKeysToCamelCase(response.data);
  } catch (error) {
    // You can handle errors here, or let them propagate up
    throw error;
  }
};

export const handleDateFormat = (dateParmam) => {
  try {
    const date = new Date(dateParmam);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options); // 'en-GB' for dd/mm/yyyy format
    return formattedDate;
  } catch (error) {
    console.error(error);
  }
};

export default (dateParam) => {
  try {
    const date = new Date(dateParam);
    const dateFormatOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    };
    const timeFormatOptions = {
      hour: "numeric",
      minute: "2-digit",
      hour12: false, // Include AM/PM
    };
    const formattedDate = date.toLocaleDateString("en-GB", dateFormatOptions);
    const formattedTime = date.toLocaleTimeString("en-GB", timeFormatOptions);
    const formattedDateTime = `${formattedDate} | ${formattedTime}`;
    return formattedDateTime;
  } catch (error) {
    console.error(error);
    return "-";
  }
};
export const handleSearchValidation = (event) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  const inputValue = event.target.value + keyValue;
  if (
    !/^[a-zA-Z0-9][a-zA-Z0-9.\s]*$/.test(inputValue) || // Require at least one character before allowing white space and dots
    !/^\d{0,10}$/.test(inputValue.replace(/\D/g, "")) || // Allow up to 10 digits
    inputValue.length >= 30 || // Limit total input length
    /\.{3,}/.test(inputValue) || // Disallow consecutive dots greater than 2
    /\s{3,}/.test(inputValue) // Disallow consecutive white spaces
  ) {
    event.preventDefault();
  }
};

export const handleValidateName = (event) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);

  // Allow only letters and whitespace
  if (!/^[a-zA-Z\s.]*$/.test(keyValue)) {
    event.preventDefault();
  }
};

export const snakeToCamel = (snakeCaseStr) => {
  return snakeCaseStr.toLowerCase().replace(/(_\w)/g, function (match) {
    return match[1].toUpperCase();
  });
};

export const convertKeysToCamelCase = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => convertKeysToCamelCase(item));
  } else if (data !== null && typeof data === "object") {
    const newObj = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const camelCaseKey = snakeToCamel(key);
        newObj[camelCaseKey] = convertKeysToCamelCase(data[key]);
      }
    }
    return newObj;
  } else {
    return data;
  }
};

export const camelToSnake = (camelCaseStr) => {
  return camelCaseStr.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const convertKeysToSnakeCase = (data) => {
  if (Array.isArray(data)) {
    return data.map((item) => convertKeysToSnakeCase(item));
  } else if (data !== null && typeof data === "object") {
    const newObj = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const snakeCaseKey = camelToSnake(key);
        newObj[snakeCaseKey] = convertKeysToSnakeCase(data[key]);
      }
    }
    return newObj;
  } else {
    return data;
  }
};
