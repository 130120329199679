import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import refresh from "../../assets/images/refresh.svg";
import StorageHelper from "../../Utility/StorageHelper";
import { FnAuthenticatedRequest } from "../../Utility/Utils";
import Select from "react-select";
import {
  fieldActivityPageNumber,
  fieldActivityPageSize,
} from "../../Utility/constants";

export default function Filters({ handleFilter }) {
  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [selectedActivityYear, setSelectedActivityYear] = useState<any>(2024);
  const [selectedStatus, setSelectedStatus] = useState<any>("Y");

  const yearOptions = [
    {
      label: "2023",
      value: "2023",
    },
    {
      label: "2024",
      value: "2024",
    },
  ];

  const statusOptions = [
    {
      label: "Active",
      value: "Y",
    },
    {
      label: "In-active",
      value: "N",
    },
  ];

  useEffect(() => {
    handleFetchStates();
  }, []);

  useEffect(() => {
    if (selectedStates?.length > 0) {
      handleFetchDistricts();
    } else {
      setDistrictOptions([]);
      setSelectedDistricts([]);
    }
  }, [selectedStates]);

  const handleFetchStates = async () => {
    try {
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      const authHeader = {
        JWTToken: authData.jwtToken,
        userId: authData.userId,
      };
      const response: any = await FnAuthenticatedRequest(
        "POST",
        "api/GetStates",
        authHeader,
        ""
      );
      if (response.success) {
        const stateObject = response.data.map((state: any) => {
          return {
            label: state.stateName?.toUpperCase(),
            value: state.stateId,
          };
        });
        setStateOptions(stateObject);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFetchDistricts = async () => {
    try {
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      const authHeader = {
        JWTToken: authData.jwtToken,
        userId: authData.userId,
      };
      const params = selectedStates?.map((state: any) => state.value);
      if (params?.length > 0) {
        const response: any = await FnAuthenticatedRequest(
          "POST",
          "api/GetDistricts",
          authHeader,
          params
        );
        if (response.success) {
          const districtObject = response.data.map((district: any) => {
            return {
              label: district.districtName?.toUpperCase(),
              value: district.districtId,
            };
          });
          setDistrictOptions(districtObject);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const customMessages = {
    allItemsAreSelected: "All Selected",
    selectSomeItems: "All",
    selectAllFiltered: "All",
  };

  return (
    <div className="container p-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 gap-5">
      <div>
        <label id="yearr">Year</label>
        <Select
          onChange={(option: any) => {
            setSelectedActivityYear(option);
          }}
          isSearchable={false}
          options={yearOptions}
          isClearable={true}
          placeholder="All"
          value={selectedActivityYear?.value}
        />
      </div>
      <div>
        <label id="states">States</label>
        <MultiSelect
          options={stateOptions}
          value={selectedStates}
          onChange={setSelectedStates}
          labelledBy="states"
          hasSelectAll={false}
          overrideStrings={customMessages}
          shouldToggleOnHover={false}
        />
      </div>
      <div>
        <label id="districts">Districts</label>
        <MultiSelect
          options={districtOptions}
          value={selectedDistricts}
          onChange={setSelectedDistricts}
          labelledBy="districts"
          hasSelectAll={false}
          overrideStrings={customMessages}
          shouldToggleOnHover={false}
        />
      </div>
      <div>
        <label id="status">Status</label>
        <Select
          onChange={(option: any) => {
            setSelectedStatus(option);
          }}
          isSearchable={false}
          options={statusOptions}
          isClearable={true}
          placeholder="All"
          value={selectedStatus}
        />
      </div>
      <div className="flex gap-4 items-center mt-4">
        <button
          className="bg-white text-[#406BBE] font-semibold border border-[#406BBE] px-5 py-1.5 rounded transition-all hover:bg-[#406BBE] hover:text-white active:bg-[#34508B] active:border-[#34508B]"
          onClick={() => {
            let filterObject = {
              page_no: fieldActivityPageNumber,
              page_size: fieldActivityPageSize,
              year: Number(selectedActivityYear?.value),
              status: selectedStatus?.value,
              states: selectedStates.map((state: any) => state.value),
              districts: selectedDistricts.map(
                (district: any) => district.value
              ),
              search_text: "",
            };
            handleFilter(filterObject);
          }}
        >
          Apply
        </button>
        <button
          onClick={() => {
            let filterObject = {
              pageNo: fieldActivityPageNumber,
              pageSize: fieldActivityPageSize,
              year: 2024,
              status: "Y",
              states: [],
              districts: [],
              searchText: "",
            };
            handleFilter(filterObject);
            setSelectedDistricts([]);
            setSelectedStates([]);
            setSelectedStatus(null);
            setSelectedActivityYear(null);
          }}
        >
          <img src={refresh} alt="home_icon" />
        </button>
      </div>
    </div>
  );
}
