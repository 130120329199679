import { useContext, useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import upload from "../../assets/images/upload.svg";
import ReactLoadingOverlay from "react-loading-overlay";
import ClipLoader from "react-spinners/ClipLoader";

import * as XLSX from "xlsx";
import {
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import guide from "../../assets/images/guidelines.svg";
import StorageHelper from "../../Utility/StorageHelper";
import { FnAuthenticatedRequest, handleDateFormat } from "../../Utility/Utils";
import { toast } from "react-toastify";
import GuideLines from "./GuideLines";
import AlertDialog from "./AlertDialog";
import { useHistory } from "react-router-dom";
import { MsalContext } from "@azure/msal-react";
import { APP_ROUTES } from "../../Libraries/AppConstants";
import { projectStartDate } from "../../Utility/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  blankCell: {
    backgroundColor: "#ff4d4d",
  },

  table: {
    minWidth: 650,
    border: "none",
    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
    },
  },
  tableContainer: {
    borderSpacing: "15px",
  },
  tableRow: {
    borderBottom: "none",
  },
  bold: {
    fontWeight: "bold",
  },
  editIcon: {
    color: "gray",
  },
}));

interface AddUsersProps {
  closeDialog: () => void;
  bulkUpload: boolean;
  users: any;
}
interface Option {
  label: string;
  value: string;
}

interface Payload {
  name: string;
  mobile: string;
  roleId: string;
  roleName: string;
  partnerId: string | null;
  altMobile: string;
  altMobileLogin: boolean;
  email: string;
  territoryMap: {
    zone: { zoneId: string; zoneName: string }[];
    state: { stateId: string; stateName: string }[];
    territory: { territoryId: string; territoryName: string }[];
    district: {
      districtId: string;
      districtName: string;
      stateId: string;
    }[];
  };
  joinedDate: Date;
  createdBy: string;
  reportingManager: string | null;
  isTestUser: boolean;
  appCode: string;
  vertical: string;
}

interface getChecckMobileMongoSqlPayloads {
  mobile: string;
  roleIds: string;
}
interface TrCheckPayloads {
  mobile: string;
  roleId: string;
  roleName: string;

  territoryMap: {
    zone: { zoneId: string; zoneName: string }[];
    state: { stateId: string; stateName: string }[];
    territory: { territoryId: string; territoryName: string }[];
    district: {
      districtId: string;
      districtName: string;
      stateId: string;
    }[];
  };
}

const BulkUpload: React.FC<AddUsersProps> = (props) => {
  const classes = useStyles();
  const [file, setFile] = useState<File | null>(null);
  const { closeDialog, bulkUpload, users } = props;
  const [rolesOptions, setRoleOptions] = useState<Option[]>([]);
  const [rmOptions, setRmOptions] = useState<any>([]);
  const [mobileCheck, setUsersOptions] = useState<any>([]);
  const [fileName, setFileName] = useState<string>("");
  const [showAllEntries, setShowAllEntries] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImportAwaayClick, setImportAwayClicked] = useState(false);
  const [nextButnClicked, setNextButtonClicked] = useState(false);
  const [currentState, setCurrentState] = useState(/* initial state */);
  const [previousState, setPreviousState] = useState(undefined);
  const [sliderValue, setSliderValue] = useState(0);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [dataImporting, setDataImporting] = useState(false);
  const [fileValidData, setFileValidData] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [fileData, setFileData] = useState<any>(null);
  const [nextData, setNextData] = useState<any>(null);
  // const [noError, setNoErrorState] = useState(true);
  const [zoneOptions, setZoneOptions] = useState<Option[]>([]);
  const [checkTrMapOptions, setCheckTrMapOptions] = useState<any>([]);
  const [partnersList, setPartnersList] = useState<any>([]);
  const [originalTableBody, setOriginalTableBody] = useState<any>(null);
  const history = useHistory();
  const msalContext = useContext(MsalContext);
  const toggleGuide = () => {
    setIsModalOpen((prevState) => !prevState);
  };
  const handleSliderChange = () => {
    const nextValue = sliderValue === 0 ? 1 : 0;
    setSliderValue(nextValue);
  };
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setPreviousState(currentState);
  }, [currentState]);

  const handlePrevClick = () => {
    if (isImportAwaayClick) {
      setImportAwayClicked(false);
      setNextButtonClicked(false);
    } else if (nextButnClicked) {
      setNextButtonClicked(false);
      setImportAwayClicked(false);
      // setFileUploaded(false);
      // setFileData(false);
    } else if (fileData) {
      setNextData(false);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (!nextButtonClicked) return;

    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const contents = e.target?.result as string;
        const workbook = XLSX.read(contents, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data: any = XLSX.utils.sheet_to_json(sheet);
        const expectedHeaders = [
          "Mobile Number",
          "Role",
          "Reporting Manager Mobile Number",
          "Partner Name",
          "Zone (Mandatory for ZBM, RBM,TBM,KA)",
          "State (Mandatory for KA,RBM,TBM)",
          "District (Mandatory for KA)",
          "Territory (Mandatory for TBM,KA)",
          "Joining Date (DD-MM-YYYY)",
          "Test User (Yes or No)(In case of empty by default No will be selected)",
        ];

        const uploadedHeaders = Object.keys(data[0]);
        const matchingHeadersCount = expectedHeaders.reduce((count, header) => {
          if (uploadedHeaders.includes(header)) {
            return count + 1;
          }
          return count;
        }, 0);

        if (matchingHeadersCount < 4) {
          setFile(null);
          const message = "Invalid file. Please try again !";
          toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
            style: {
              backgroundColor: "#ff4d4d",
              color: "white",
            },
            autoClose: 2000,
            toastId: "customErrorToast",
          });
          const fileInput = document.getElementById("fileInput");
          if (fileInput !== null && fileInput !== undefined) {
            (fileInput as HTMLInputElement).value = "";
          }

          return;
        }
        setSliderValue(0.5);
      } catch (error) {
        setFile(null);
        const message = "Invalid file. Please try again !";
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
          style: {
            backgroundColor: "#ff4d4d",
            color: "white",
          },
          autoClose: 2000,
          toastId: "customErrorToast",
        });
        const fileInput = document.getElementById("fileInput");
        if (fileInput !== null && fileInput !== undefined) {
          (fileInput as HTMLInputElement).value = "";
        }

        return;
      }
    };

    reader.onerror = function () {
      const message = "Failed to read the file. Please try again";
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        style: {
          backgroundColor: "#ff4d4d",
          color: "white",
        },
        autoClose: 3000,
        toastId: "customErrorToast",
      });
    };

    setFile(file);
    const fileName = file.name;
    setFileName(fileName);
    setFileUploaded(true);
    reader.readAsBinaryString(file);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleRemoveFile = () => {
    setFile(null);
    setFileName("");
    setFileUploaded(false);
    setFileData(null);
    // Reset file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const isImportAwayClicked = async () => {
    if (unsatisfiedRows?.length > 0) {
      setImportAwayClicked(true);
      setShowAllEntries(false);
    }
  };

  const nextButtonClicked = async () => {
    try {
      const reader = new FileReader();

      reader.onload = function (e) {
        const contents = e.target?.result as string;
        const workbook = XLSX.read(contents, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        setFileData(data);

        setNextData(data);
        fetchRoles();
        fetchDataFromApi();
        fetchUsers();
        fnGetPartnerList();
        fnGetReportingManager();
        handleTerritoryMapError();
        setSliderValue(0.5);
      };
      setNextButtonClicked(true);

      const fileInput = document.getElementById("fileInput");
      if (fileInput instanceof HTMLInputElement && fileInput.files?.length) {
        reader.readAsBinaryString(fileInput.files[0]);
      }
    } catch (error) {
      console.error("Error reading file:", error);
    }
  };

  useEffect(() => {
    if (rolesOptions.length > 0) {
      fetchUsers();
    }
  }, [rolesOptions]);

  const fetchUsers = async () => {
    setUsersOptions(null);
    const payloads: getChecckMobileMongoSqlPayloads[] = [];

    if (fileData) {
      fileData.forEach((userData: { [key: string]: any }) => {
        let roleId = "";

        rolesOptions.forEach((role) => {
          if (
            role?.label?.trim().toUpperCase() ===
            userData["Role"]?.toString().trim().toUpperCase()
          ) {
            roleId = role?.value;
            return;
          }
        });
        const payload: getChecckMobileMongoSqlPayloads = {
          mobile: `${userData["Mobile Number"]}`,
          roleIds: roleId,
        };

        payloads?.push(payload);
      });
    } else {
      return;
    }

    try {
      setDataLoading(true);
      const authData = JSON.parse(localStorage.getItem("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken: authData?.jwtToken,
          userId: authData?.userId,
        };

        const response: any = await FnAuthenticatedRequest(
          "POST",
          "api/CheckUserExist",
          authHeader,
          payloads
        );

        const formattedData = response?.data;

        if (response?.success) {
          setTimeout(() => {
            setDataLoading(false);
          }, 1300);
          setUsersOptions(formattedData);
        }
      }
    } catch (error: any) {
      setTimeout(() => {
        setDataLoading(false);
      }, 1000);
      console.error("Error fetching users  data:", error);
      if (error.response && error.response.status) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          StorageHelper.removeAll();
          const msalInstance = msalContext.instance;
          // msalInstance.logoutPopup();
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          history.replace(APP_ROUTES.LOGIN);
        } else {
          setTimeout(() => {
            setDataLoading(false);
          }, 1300);
          console.error("Error fetching users data:", error);
        }
      } else {
        setTimeout(() => {
          setDataLoading(false);
        }, 1000);
        console.error("Error fetching  usrs data:", error);
      }
    }
  };

  const fnGetPartnerList = async () => {
    try {
      setDataLoading(true);
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData.userId,
        };

        const response = await FnAuthenticatedRequest(
          "POST",
          "api/getPartnerKa",
          authHeader,
          ""
        );

        if (response?.success) {
          if (response.data.some((item) => item.hasOwnProperty("partnerId"))) {
            setPartnersList(response.data);
            setTimeout(() => {
              setDataLoading(false);
            }, 1300);
          } else {
            setDataLoading(true);
            fnGetPartnerList();
          }
        }
      }
    } catch (error: any) {
      setTimeout(() => {
        setDataLoading(false);
      }, 1300);
      console.error("Error fetching partners  data:", error);
      if (error.response && error.response.status) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          StorageHelper.removeAll();
          const msalInstance = msalContext.instance;
          // msalInstance.logoutPopup();
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          history.replace(APP_ROUTES.LOGIN);
        } else {
          setTimeout(() => {
            setDataLoading(false);
          }, 1300);
          console.error("Error fetching partners data:", error);
        }
      } else {
        setTimeout(() => {
          setDataLoading(false);
        }, 1300);

        console.error("Error fetching  partners data:", error);
      }
    }
  };

  function isValidReportingManagerMobileNumber(mobileNumber, role, userData) {
    if (!userData) {
      return false;
    }

    // var isTestUser = isValidTestUser(role, userData);

    const rmMobile = userData["Reporting Manager Mobile Number"];

    var testUser =
      userData[
        "Test User (Yes or No)(In case of empty by default No will be selected)"
      ];

    var isTestUser =
      typeof testUser === "string" && testUser.trim().toLowerCase() === "yes";

    if (typeof testUser !== "string" || testUser.trim() === "") {
      isTestUser = false;
    }

    if (rmMobile) {
      var rmData = rmOptions?.find(
        (rm) => rm.rmMobile?.toString() === rmMobile?.toString()
      );

      if (rmData) {
        if (
          (isTestUser != rmData?.isTestUser ||
            !isTestUser == rmData?.isTestUser ||
            isTestUser == !rmData?.isTestUser) &&
          role?.toUpperCase() !== "NM"
        ) {
          return false;
        }
      }
    }

    const roles =
      typeof userData["Role"] === "string" ? userData?.Role?.toUpperCase() : "";

    if (roles === "NM" && !mobileNumber) {
      return true;
    }
    if (roles === "NM" && mobileNumber) {
      return false;
    }
    if (!/^[1-9][0-9]{9}$/.test(mobileNumber)) {
      return false;
    }

    if (!/^\d+$/.test(mobileNumber)) {
      return false;
    }

    if (/[!@#$%^&*(),.?":{}|<>]/.test(mobileNumber)) {
      return false;
    }

    const matchingChecks = rmOptions?.filter(
      (check) =>
        check?.rmMobile === `${userData["Reporting Manager Mobile Number"]}`
    );

    if (!matchingChecks || matchingChecks.length === 0) {
      return false;
    }

    let anyMatchingRole = false;

    for (const matchingCheck of matchingChecks) {
      const matchingRole = rolesOptions?.find(
        (role) => role?.value === matchingCheck?.roleId
      );

      switch (roles?.toUpperCase()) {
        case "ZBM":
          if (matchingRole?.label?.toUpperCase() === "NM") {
            anyMatchingRole = true;
          }
          break;

        case "RBM":
          if (
            matchingRole?.label?.toUpperCase() === "ZBM" ||
            matchingRole?.label?.toUpperCase() === "NM"
          ) {
            anyMatchingRole = true;
          }
          break;

        case "TBM":
          if (
            matchingRole?.label?.toUpperCase() === "RBM" ||
            matchingRole?.label?.toUpperCase() === "ZBM"
          ) {
            anyMatchingRole = true;
          }
          break;

        case "KA":
          if (
            matchingRole?.label?.toUpperCase() === "TBM" ||
            matchingRole?.label?.toUpperCase() === "RBM"
          ) {
            anyMatchingRole = true;
          }
          break;

        default:
          break;
      }

      if (
        matchingRole &&
        matchingRole?.label?.toUpperCase() === roles?.toUpperCase()
      ) {
        return false;
      }
    }

    return anyMatchingRole;
  }

  useEffect(() => {
    if (fileData) {
      fetchRoles();
      fetchDataFromApi();
      fetchUsers();
      fnGetPartnerList();
      fnGetReportingManager();
      handleTerritoryMapError();
    }
  }, [fileData]);

  const fetchRoles = async () => {
    setDataLoading(true);

    try {
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData?.userId,
        };
        const response: any = await FnAuthenticatedRequest(
          "POST",
          "api/GetRoles",
          authHeader,
          ""
        );

        const hasRoleId = response?.data.some((role) =>
          role.hasOwnProperty("roleId")
        );

        if (hasRoleId) {
          setTimeout(() => {
            setDataLoading(false);
          }, 1300);

          const formattedData = response?.data
            ?.filter(
              (role) =>
                role.name &&
                ["ZBM", "RBM", "KA", "TBM", "NM"]?.some((keyword) =>
                  role.name.includes(keyword)
                ) &&
                role.hasOwnProperty("roleId")
            )
            ?.map((role) => ({
              label: role?.name?.toUpperCase(),
              value: role?.roleId,
              role_name: role?.name?.toUpperCase(),
            }));

          setRoleOptions(formattedData);
        } else {
          setDataLoading(true);
          fetchRoles();
        }
      }
    } catch (error) {
      setTimeout(() => {
        setDataLoading(false);
      }, 1300);
      console.error("Error fetching roles:", error);
    }
  };

  const fnGetReportingManager = async () => {
    try {
      setDataLoading(true);
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      const authHeader = {
        JWTToken: authData?.jwtToken,
        userId: authData?.userId,
      };

      const reportingManagerMobileNumbers: string[] = [];

      if (fileData) {
        fileData?.forEach((userData: { [key: string]: any }) => {
          const reportingManagerMobile =
            `${userData["Reporting Manager Mobile Number"]}`.trim();
          if (reportingManagerMobile !== "undefined") {
            reportingManagerMobileNumbers.push(reportingManagerMobile);
          }
        });
      } else {
        return;
      }

      const response: any = await FnAuthenticatedRequest(
        "POST",
        "api/CheckMobileUser",
        authHeader,
        reportingManagerMobileNumbers
      );

      if (response?.success) {
        setTimeout(() => {
          setDataLoading(false);
        }, 1300);

        const data: any = response?.data;
        interface RmOption {
          label: string;
          value: any;
          rmId: any;
          rmName: string;
          rmMobile: any;
          roleId: string;
          isTestUser: boolean;
        }

        let rmOptions: RmOption[] = [];

        data.forEach((rm) => {
          rm.rolesForKa.forEach((role) => {
            let rmObject: RmOption = {
              label: rm?.name?.toUpperCase(),
              value: rm?.userId,
              rmId: rm?.userId,
              rmName: rm?.name?.toUpperCase(),
              rmMobile: rm?.mobile,
              roleId: role?.roleId,
              isTestUser: rm.isTestUser,
            };
            rmOptions.push(rmObject);
          });
        });
        setRmOptions(rmOptions);
      } else {
        setTimeout(() => {
          setDataLoading(false);
        }, 1300);
        console.error("Error fetching reporting manager:", response?.data);
      }
    } catch (error) {
      setTimeout(() => {
        setDataLoading(false);
      }, 1300);
      console.error("Error fetching reporting manager:", error);
    }
  };

  const fetchDataFromApi = async () => {
    setDataLoading(true);
    const userMobileNumbers: string[] = [];

    if (fileData) {
      fileData.forEach((userData: { [key: string]: any }) => {
        const mobile = `${userData["Mobile Number"]}`.trim();
        userMobileNumbers.push(mobile);
      });
    } else {
      return;
    }
    try {
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData.userId,
        };
        const response: any = await FnAuthenticatedRequest(
          "POST",
          "api/GetZoneDetails",
          authHeader,
          ""
        );
        if (response?.success) {
          setTimeout(() => {
            setDataLoading(false);
          }, 1300);

          const formattedData = response?.data?.map((zone) => ({
            label: zone.zoneName?.toUpperCase(),
            value: zone.zoneId,
            zoneId: zone.zoneId,
            zoneName: zone.zoneName?.toUpperCase(),
          }));
          setZoneOptions(formattedData);
        }
      }
    } catch (error) {
      setTimeout(() => {
        setDataLoading(false);
      }, 1300);
      console.error("Error fetching zone data:", error);
    }
  };

  const handleTerritoryMapError = async () => {
    setDataLoading(true);
    // e.preventDefault();
    try {
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      const authHeader = {
        JWTToken: authData?.jwtToken,
        userId: authData?.userId,
      };
      const filteredData = fileData;

      const payloads: TrCheckPayloads[] = [];

      for (let userData of filteredData) {
        let roleId = "";

        rolesOptions.forEach((role) => {
          if (
            role?.label?.trim().toUpperCase() ===
            userData["Role"]?.toString().trim().toUpperCase()
          ) {
            roleId = role?.value;
            return;
          }
        });

        const zoneData = userData["Zone (Mandatory for ZBM, RBM,TBM,KA)"]
          ? userData["Zone (Mandatory for ZBM, RBM,TBM,KA)"]
              .toString()
              .toUpperCase()
              .split(",")
              .map((zone) => zone.trim())
          : [];
        const stateData = userData["State (Mandatory for KA,RBM,TBM)"]
          ? userData["State (Mandatory for KA,RBM,TBM)"]
              .toString()
              .toUpperCase()
              .split(",")
              .map((state) => state.trim())
          : [];
        const territoryData = userData["Territory (Mandatory for TBM,KA)"]
          ? userData["Territory (Mandatory for TBM,KA)"]
              .toString()
              .toUpperCase()
              .split(",")
              .map((territory) => territory.trim())
          : [];
        const districtData = userData["District (Mandatory for KA)"]
          ? userData["District (Mandatory for KA)"]
              .toString()
              .toUpperCase()
              .split(",")
              .map((district) => district.trim())
          : [];

        const territorymap = {
          zone:
            zoneData?.length > 0
              ? zoneData.map((zone) => ({ zoneId: "", zoneName: zone }))
              : [],
          state:
            stateData?.length > 0
              ? stateData.map((state) => ({ stateId: "", stateName: state }))
              : [],
          territory:
            territoryData?.length > 0
              ? territoryData.map((territory) => ({
                  territoryId: "",
                  territoryName: territory,
                }))
              : [],
          district:
            districtData?.length > 0
              ? districtData.map((district) => ({
                  districtId: "",
                  districtName: district,
                  stateId: "",
                }))
              : [],
        };

        const payload: TrCheckPayloads = {
          mobile: `${
            !userData["Mobile Number"] ? "" : userData["Mobile Number"]
          }`,
          roleId: roleId,
          roleName:
            typeof userData["Role"] !== "string"
              ? ""
              : userData["Role"]?.toString().toUpperCase(),
          territoryMap: territorymap,
        };

        payloads?.push(payload);
      }
      const response: any = await FnAuthenticatedRequest(
        "POST",
        "api/CheckMapDetails",
        authHeader,
        payloads
      );

      if (response?.success) {
        const dataArray = response.data;
        if (Array.isArray(dataArray)) {
          const checkTrMapOptions = dataArray?.map((item) => ({
            mobile: item?.mobile,
            trMap: item?.territoryMap,
          }));
          setCheckTrMapOptions(checkTrMapOptions);
          setTimeout(() => {
            setDataLoading(false);
          }, 1300);
        } else {
          setTimeout(() => {
            setDataLoading(false);
          }, 1300);

          console.error("Data not found");
        }
      } else {
        setTimeout(() => {
          setDataLoading(false);
        }, 1300);

        console.error("Data not found");
      }
    } catch (error) {
      setTimeout(() => {
        setDataLoading(false);
      }, 1300);
      console.error(error);
    }
  };

  const handleCreateUser = async (e: any) => {
    e.preventDefault();
    try {
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      const authHeader = {
        JWTToken: authData?.jwtToken,
        userId: authData?.userId,
      };
      const filteredData = filterRows(fileData, false);

      const payloads: Payload[] = [];

      for (let userData of filteredData) {
        let partnerId = null;

        partnersList?.forEach((partner) => {
          if (
            partner?.name?.trim()?.toUpperCase() ===
            userData["Partner Name"]?.trim().toUpperCase()
          ) {
            partnerId = partner?.partnerId;
            return;
          }
        });

        let reportingManagerId = null;

        rmOptions.forEach((user) => {
          const tmobile =
            `${userData["Reporting Manager Mobile Number"]}`.trim();
          const userMobile = user?.rmMobile;
          if (userMobile === tmobile) {
            reportingManagerId = user?.rmId;
            return;
          }
        });

        let roleId = "";

        rolesOptions.forEach((role) => {
          if (
            role?.label?.trim().toUpperCase() ===
            userData?.Role?.trim().toUpperCase()
          ) {
            roleId = role?.value;
            return;
          }
        });

        const zoneData = userData["Zone (Mandatory for ZBM, RBM,TBM,KA)"]
          ? userData["Zone (Mandatory for ZBM, RBM,TBM,KA)"]
              .toUpperCase()
              .split(",")
              .map((zone) => zone.trim())
          : [];
        const stateData = userData["State (Mandatory for KA,RBM,TBM)"]
          ? userData["State (Mandatory for KA,RBM,TBM)"]
              .toUpperCase()
              .split(",")
              .map((state) => state.trim())
          : [];
        const territoryData = userData["Territory (Mandatory for TBM,KA)"]
          ? userData["Territory (Mandatory for TBM,KA)"]
              .toUpperCase()
              .split(",")
              .map((territory) => territory.trim())
          : [];
        const districtData = userData["District (Mandatory for KA)"]
          ? userData["District (Mandatory for KA)"]
              .toUpperCase()
              .split(",")
              .map((district) => district.trim())
          : [];

        const isTestUser =
          userData[
            "Test User (Yes or No)(In case of empty by default No will be selected)"
          ] &&
          (userData[
            "Test User (Yes or No)(In case of empty by default No will be selected)"
          ]
            .toLowerCase()
            .includes("yes") ||
            userData[
              "Test User (Yes or No)(In case of empty by default No will be selected)"
            ]
              .toLowerCase()
              .includes("y"));

        const joinedDate = userData["Joining Date (DD-MM-YYYY)"];

        const data = String(joinedDate);
        let dd;

        if (data.includes("/")) {
          const parts = data.split("/");
          parts[1] = String(parts[1]).padStart(2, "0");
          parts[0] = String(parts[0]).padStart(2, "0");

          const jdate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
          dd = new Date(jdate + " UTC");
        } else {
          const date = XLSX.SSF.parse_date_code(joinedDate);
          const jdate = date?.m + "-" + date?.d + "-" + date?.y;
          dd = new Date(jdate + " UTC");
        }

        const territorymap = {
          zone:
            zoneData?.length > 0
              ? zoneData.map((zone) => ({ zoneId: "", zoneName: zone }))
              : [],
          state:
            stateData?.length > 0
              ? stateData.map((state) => ({ stateId: "", stateName: state }))
              : [],
          territory:
            territoryData?.length > 0
              ? territoryData.map((territory) => ({
                  territoryId: "",
                  territoryName: territory,
                }))
              : [],
          district:
            districtData?.length > 0
              ? districtData.map((district) => ({
                  districtId: "",
                  districtName: district,
                  stateId: "",
                }))
              : [],
        };

        const payload: Payload = {
          name: userData.Name,
          mobile: `${userData["Mobile Number"]}`,
          roleId: roleId,
          roleName: userData["Role"]?.toUpperCase(),
          partnerId: partnerId,
          altMobile: "",
          altMobileLogin: false,
          email: "",
          territoryMap: territorymap,
          joinedDate: typeof dd === "object" ? dd?.toISOString() : dd,
          createdBy: authData.email,
          reportingManager: reportingManagerId,
          isTestUser: isTestUser,
          appCode: "carbon-bo",
          vertical: "carbon",
        };

        payloads.push(payload);
      }

      const response: any = await FnAuthenticatedRequest(
        "POST",
        "api/AddKaUserMultiple",
        authHeader,
        payloads
      );
      if (response?.success) {
        setDataImporting(true);
        setTimeout(() => {
          window.location.reload();
        }, 500);
        const entryCount = payloads?.length;
        const message = `✓ ${entryCount} Entry Import${
          entryCount !== 1 ? "s" : ""
        } Successful`;
        toast?.success(message, {
          position: toast.POSITION.TOP_CENTER,
          style: {
            backgroundColor: "#258750",
            color: "white",
          },
          autoClose: 2000,
          toastId: "customErrorToast",
        });
        setTimeout(() => {
          setDataImporting(false);
        }, 1500);
      } else {
        toast.error(response?.error || "Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          toastId: "customErrorToast",
        });
      }
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        toastId: "customErrorToast",
      });
    }
  };

  function isNameValidate(name) {
    // Check if name is blank or contains only whitespace
    if (!name || /^\s*$/.test(name)) {
      return false;
    }

    // Check if first character is whitespace
    if (name[0] === " ") {
      return false;
    }

    // Check if name has at least 5 characters and at most 30 characters
    if (name?.length < 4 || name?.length > 30) {
      return false;
    }

    // Check if name contains digits
    if (/\d/.test(name)) {
      return false;
    }

    // Check if name contains special characters other than dot
    if (!/^[a-zA-Z\s.]*$/.test(name)) {
      return false;
    }

    // Check if dot appears after a character
    if (/^[^.]*\.(?!\.)[^.]*$/.test(name)) {
      return true;
    }

    // Check if name starts or ends with dot
    if (name.startsWith(".")) {
      return false;
    }

    return true;
  }
  function isValidMobileNumber(mobileNumber, fileData, currentIndex) {
    if (!/^[1-9]\d{9}$/.test(mobileNumber)) {
      return false;
    }

    for (let i = 0; i < fileData?.length; i++) {
      if (i !== currentIndex && fileData[i]["Mobile Number"] === mobileNumber) {
        return false;
      }
    }

    // const isMobileNumberValid = Array.isArray(mobileCheck) &&
    // !mobileCheck?.some(
    //     (check) => check?.mobile === `${fileData["Mobile Number"]}`
    // );

    if (!mobileNumber || /^\s*$/.test(mobileNumber)) {
      return false;
    }

    // Check if mobileNumber has exactly 10 digits starting with a non-zero digit
    if (!/^[1-9][0-9]{9}$/.test(mobileNumber)) {
      return false;
    }

    // Check if mobileNumber contains any non-digit characters
    if (/\D/.test(mobileNumber)) {
      return false;
    }

    return true;
  }

  function isValidRole(role) {
    if (typeof role !== "string" || !role.trim()) {
      return false;
    }

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const digitRegex = /\d/;
    const leadingWhiteSpaceRegex = /^\s/;

    const roleExists = rolesOptions?.some(
      (option) => option?.label?.toUpperCase() === role?.toUpperCase()
    );

    const hasSpecialChars = specialCharRegex?.test(role);
    const hasDigits = digitRegex.test(role);
    const hasLeadingWhiteSpace = leadingWhiteSpaceRegex.test(role);

    if (!roleExists || hasSpecialChars || hasDigits || hasLeadingWhiteSpace) {
      return false;
    }

    return true;
  }

  const handleJoinedDate = (joinedDate) => {
    if (!joinedDate) return "";

    const data = String(joinedDate);
    let dd;

    if (data.includes("/")) {
      const parts = data.split("/");
      parts[1] = String(parts[1]).padStart(2, "0");
      parts[0] = String(parts[0]).padStart(2, "0");

      const dateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
      const jdate = new Date(dateString);

      if (jdate.getFullYear() < projectStartDate) {
        return "Invalid Date";
      }

      var isValid = isValidDate(dateString);
      if (!isValid) {
        return "Invalid Date";
      }
      dd = new Date(jdate + " UTC");
    } else {
      const date = XLSX.SSF.parse_date_code(joinedDate);
      if (date?.y < projectStartDate) {
        return "Invalid Date";
      }
      const dateString = date?.m + "-" + date?.d + "-" + date?.y;
      dd = new Date(dateString + " UTC");
    }
    return handleDateFormat(dd);
  };

  function isValidDate(dateString) {
    const parts = dateString.split("-");
    if (parts.length !== 3) return false;

    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[2], 10);

    const date = new Date(year, month, day);

    return (
      date.getFullYear() === year &&
      date.getMonth() === month &&
      date.getDate() === day
    );
  }

  function isValidPartnerName(partnerName) {
    if (!partnerName || /^\s*$/.test(partnerName)) {
      return false;
    }

    if (partnerName?.length < 3) {
      return false;
    }

    // Check if partnerName contains digits
    if (/\d/.test(partnerName)) {
      return false;
    }

    // Check if partnerName contains special characters other than dot
    // if (!/^[a-zA-Z\s.]*$/.test(partnerName)) {
    //   return false;
    // }

    // Check if partnerName starts or ends with whitespace
    // if (partnerName.trim() !== partnerName) {
    //   return false;
    // }

    // Check if dot appears after a character
    if (/^.*\.\..*$/.test(partnerName)) {
      return false;
    }

    if (partnerName?.startsWith(".")) {
      return false;
    }
    const isMatchFound =
      Array?.isArray(partnersList) &&
      partnersList?.some((partner) => {
        const partnerUpperCase = partner?.name?.toUpperCase();
        const partnerNameUpperCase = partnerName?.toUpperCase();
        return (
          partnerUpperCase.trim() === partnerNameUpperCase.trim() &&
          partner.name?.trim()?.length === partnerName?.trim()?.length
        );
      });

    return isMatchFound;

    return true;
  }

  function isValidZoneContent(role, userData) {
    if (!userData) {
      return false;
    }
    const territory = userData["Territory (Mandatory for TBM,KA)"]
      ?.replace(/\s+/g, " ")
      .trim();
    const district = userData["District (Mandatory for KA)"]
      ?.replace(/\s+/g, " ")
      .trim();
    const state = userData["State (Mandatory for KA,RBM,TBM)"]
      ?.replace(/\s+/g, " ")
      .trim();
    const content = userData["Zone (Mandatory for ZBM, RBM,TBM,KA)"]
      ?.replace(/\s+/g, " ")
      .trim();

    if (typeof userData["Role"] !== "string") {
      return false;
    }

    const roles =
      typeof userData["Role"] === "string" ? userData?.Role?.toUpperCase() : "";

    if (roles !== "ZBM" && content && content?.includes(",")) {
      return false;
    }

    if (roles === "NM" && !content) {
      return true;
    }

    if (roles !== "NM") {
      if (
        checkTrMapOptions?.some(
          (check) =>
            check?.mobile === `${userData["Mobile Number"]}` &&
            check?.trMap?.state[0]?.stateName?.toUpperCase() ===
              state?.toUpperCase() &&
            check?.trMap?.district[0]?.districtName?.toUpperCase() ===
              district?.toUpperCase() &&
            check?.trMap?.territory[0]?.territoryName?.toUpperCase() ===
              territory?.toUpperCase() &&
            check?.trMap?.zone[0]?.zoneName === content?.toUpperCase()
        )
      ) {
        return false;
      }
    } else if (roles === "NM" && content) {
      return false;
    }

    // if (content?.length < 3) {
    //   return false;
    // }
    if ((roles === "NM" && content) || (roles !== "NM" && !content)) {
      return false;
    }

    // if (/\d/.test(content) || /^\s/.test(content) || /^[,\s]/.test(content)) {
    //   return false;
    // }

    // if (/[^a-zA-Z,]|,\s*,|,$/.test(content)) {
    //   return false;
    // }

    const isZoneFound =
      content && content?.length > 0
        ? zoneOptions?.find(
            (zone) => zone?.label?.toUpperCase() === content?.toUpperCase()
          )
        : null;

    if (
      isZoneFound &&
      isZoneFound?.label?.toUpperCase() !== content?.toUpperCase()
    ) {
      return false;
    }

    // setZoneValues((prevValues) => ({
    //   ...prevValues,
    //   [userData.id]: isZoneFound ? isZoneFound.value : null)}

    return { zoneValue: isZoneFound ? isZoneFound.value : null };
  }

  function isValidStateContent(role, userData) {
    if (!userData) {
      return false;
    }

    const zone = userData["Zone (Mandatory for ZBM, RBM,TBM,KA)"]
      ?.replace(/\s+/g, " ")
      .trim();
    const territory = userData["Territory (Mandatory for TBM,KA)"]
      ?.replace(/\s+/g, " ")
      .trim();
    const district = userData["District (Mandatory for KA)"]
      ?.replace(/\s+/g, " ")
      .trim();
    const content = userData["State (Mandatory for KA,RBM,TBM)"]
      ?.replace(/\s+/g, " ")
      .trim();

    if (typeof userData["Role"] !== "string") {
      return false;
    }

    const roles =
      typeof userData["Role"] === "string" ? userData?.Role?.toUpperCase() : "";

    if (roles === "RBM" && content && content?.includes(",")) {
      return true;
    }
    if ((roles === "NM" || roles === "ZBM") && !content) {
      return true;
    }

    if (roles !== "ZBM" || roles !== "NM") {
      if (
        checkTrMapOptions?.some(
          (check) =>
            check?.mobile === `${userData["Mobile Number"]}` &&
            check?.trMap?.state[0]?.stateName === content?.toUpperCase() &&
            check?.trMap?.district[0]?.districtName ===
              district?.toUpperCase() &&
            check?.trMap?.territory[0]?.territoryName ===
              territory?.toUpperCase() &&
            check?.trMap?.zone[0]?.zoneName === zone?.toUpperCase()
        )
      ) {
        return false;
      }
    } else if ((roles === "NM" || roles === "ZBM") && content) {
      return false;
    }

    if ((roles === "NM" || roles === "ZBM") && !content) {
      return true;
    }

    if ((roles === "NM" && content) || (roles === "ZBM" && content)) {
      return false;
    }

    if ((roles !== "NM" && !content) || (roles !== "ZBM" && !content)) {
      return false;
    }

    // if (/\d/.test(content) || /^\s/.test(content) || /^[,\s]/.test(content)) {
    //   return false;
    // }

    // if (/[^a-zA-Z,]|,\s*,|,$/.test(content)) {
    //   return false;
    // }

    return true;
  }

  function isValidDistrictContent(role, userData) {
    if (!userData) {
      return false;
    }
    const zone = userData["Zone (Mandatory for ZBM, RBM,TBM,KA)"]
      ?.replace(/\s+/g, " ")
      .trim();
    const territory = userData["Territory (Mandatory for TBM,KA)"]
      ?.replace(/\s+/g, " ")
      .trim();
    const state = userData["State (Mandatory for KA,RBM,TBM)"]
      ?.replace(/\s+/g, " ")
      .trim();
    const content = userData["District (Mandatory for KA)"]
      ?.replace(/\s+/g, " ")
      .trim();

    if (typeof userData["Role"] !== "string") {
      return false;
    }

    const roles =
      typeof userData["Role"] === "string" ? userData?.Role?.toUpperCase() : "";

    if (roles !== "KA" && !content) {
      return true;
    }

    if (roles == "KA") {
      if (
        checkTrMapOptions?.some(
          (check) =>
            check?.mobile === `${userData["Mobile Number"]}` &&
            check?.trMap?.state[0]?.stateName === state?.toUpperCase() &&
            check?.trMap?.district[0]?.districtName ===
              content?.toUpperCase() &&
            check?.trMap?.territory[0]?.territoryName ===
              territory?.toUpperCase() &&
            check?.trMap?.zone[0]?.zoneName === zone?.toUpperCase()
        )
      ) {
        return false;
      }
    } else if (roles !== "NM" && content) {
      return false;
    }

    if (/\d/.test(content) || /^\s/.test(content) || /^[,\s]/.test(content)) {
      return false;
    }

    if (/[^a-zA-Z,]|,\s*,|,$/.test(content)) {
      return false;
    }

    if (roles === "KA" && content) {
      return true;
    }

    if (roles !== "KA" && !content) {
      return true;
    }

    if (roles === "KA" && !content) {
      return false;
    }

    if (roles !== "KA" && content) {
      return false;
    }
  }

  function isValidTerritoryContent(role, userData) {
    const content = userData["Territory (Mandatory for TBM,KA)"]
      ?.replace(/\s+/g, " ")
      .trim();

    const zone = userData["Zone (Mandatory for ZBM, RBM,TBM,KA)"]
      ?.replace(/\s+/g, " ")
      .trim();
    const district = userData["District (Mandatory for KA)"]
      ?.replace(/\s+/g, " ")
      .trim();
    const state = userData["State (Mandatory for KA,RBM,TBM)"]
      ?.replace(/\s+/g, " ")
      .trim();

    if (typeof userData["Role"] !== "string") {
      return false;
    }

    const roles =
      typeof userData["Role"] === "string" ? userData?.Role?.toUpperCase() : "";

    if ((roles === "TBM" || roles === "KA") && content?.includes(",")) {
      return false;
    }

    if ((roles === "TBM" || roles === "KA") && !content) {
      return false;
    }

    if (
      (roles === "TBM" || roles === "KA") &&
      checkTrMapOptions?.some(
        (check) =>
          check?.mobile === `${userData["Mobile Number"]}` &&
          check?.trMap?.district[0]?.districtName === district?.toUpperCase() &&
          check?.trMap?.state[0]?.stateName === state?.toUpperCase() &&
          check?.trMap?.territory[0]?.territoryName ===
            content?.toUpperCase() &&
          check?.trMap?.zone[0]?.zoneName === zone?.toUpperCase()
      )
    ) {
      return false;
    }

    if (roles !== "KA" && roles !== "TBM" && content) {
      return false;
    }

    return true;
  }

  function isValidTestUser(role, userData) {
    if (!userData) {
      return false;
    }
    const test = (
      userData[
        "Test User (Yes or No)(In case of empty by default No will be selected)"
      ] || ""
    )
      .toString()
      .toLowerCase();

    if (test !== "yes" && test !== "no" && test !== "") {
      return false;
    }

    if (test === "yes") {
      var userMobile = userData["Mobile Number"];
      var rmMobile = userData["Reporting Manager Mobile Number"];
      var b = rmOptions?.find(
        (r) => r?.rmMobile?.toString() === rmMobile?.toString()
      );
      if (b?.isTestUser === false) {
        return false;
      }
    }

    //   if (typeof test == 'string') {
    //     return false;
    // }

    //   if ( test.length > 4 ){
    //     return false;
    //   }

    // Check if name contains digits
    if (/\d/.test(test)) {
      return false;
    }

    // Check if name contains special characters other than dot
    if (!/^[a-zA-Z]*$/.test(test)) {
      return false;
    }

    return true;
  }

  const filterRows = (fileData, includeUnsatisfiedData = false) => {
    if (fileData && Array.isArray(fileData)) {
      return fileData.filter((userData, index) => {
        // console.log(typeof userData["Role"], "userData");
        const isSatisfied =
          userData &&
          typeof userData?.Role === "string" &&
          isNameValidate(userData.Name) &&
          isValidRole(userData?.Role) &&
          isValidReportingManagerMobileNumber(
            userData["Reporting Manager Mobile Number"],
            userData["Role"],
            userData
          ) &&
          isValidPartnerName(userData["Partner Name"]) &&
          isValidZoneContent(userData?.Role, userData) &&
          isValidStateContent(
            userData["State (Mandatory for KA,RBM,TBM)"],
            userData
          ) &&
          isValidDistrictContent(
            userData["District (Mandatory for KA)"],
            userData
          ) &&
          isValidTerritoryContent(
            userData["Territory (Mandatory for TBM,KA)"],
            userData
          ) &&
          handleJoinedDate(userData["Joining Date (DD-MM-YYYY)"]) !== "" &&
          handleJoinedDate(userData["Joining Date (DD-MM-YYYY)"]) !==
            "Invalid Date" &&
          isValidMobileNumber(userData["Mobile Number"], fileData, index) &&
          !mobileCheck?.some(
            (check) => check?.mobile === `${userData["Mobile Number"]}`
          ) &&
          isValidTestUser(
            userData[
              "Test User (Yes or No)(In case of empty by default No will be selected)"
            ],
            userData
          );
        return includeUnsatisfiedData ? !isSatisfied : isSatisfied;
      });
    }
    return [];
  };

  const allRows = filterRows(fileData, true);
  const satisfiedRows = allRows.filter((row) => row.isSatisfied);
  const unsatisfiedRows = filterRows(fileData, true);
  const filteredRows = filterRows(fileData, false);

  // useEffect(() => {
  //   let timeoutId: NodeJS.Timeout | null = null;

  //   if (
  //     (filteredRows?.length === 0 &&
  //       unsatisfiedRows?.length !== 0  &&
  //       !dataLoading  &&
  //       nextButnClicked) ||
  //      ( fileData?.length===0  &&  !dataLoading  &&
  //      nextButnClicked)
  //   ) {
  //     timeoutId = setTimeout(() => {
  //       const message = "No Data For Import! Please try again";
  //       toast.error(message, {
  //         position: toast.POSITION.TOP_CENTER,
  //         style: {
  //           backgroundColor: "#ff4d4d",
  //           color: "white",
  //         },
  //         autoClose: 2000,
  //         toastId: "customErrorToast",
  //       });
  //     }, 10);
  //   }

  //   return () => {
  //     if (timeoutId) {
  //       clearTimeout(timeoutId);
  //     }
  //   };
  // }, [fileData,dataLoading]);

  useEffect(() => {
    const originalTableBody = fileData ? (
      <TableBody>
        {fileData.map((userData, index) => (
          <TableRow key={index} className="border-0 text-nowrap">
            <TableCell
              className={isNameValidate(userData.Name) ? "" : classes.blankCell}
            >
              {userData.Name}
            </TableCell>
            <TableCell
              className={
                !isValidMobileNumber(
                  userData["Mobile Number"],
                  fileData,
                  index
                ) ||
                (Array?.isArray(mobileCheck) &&
                  mobileCheck?.some(
                    (check) => check?.mobile === `${userData["Mobile Number"]}`
                  ))
                  ? classes.blankCell
                  : ""
              }
            >
              {userData["Mobile Number"]}
            </TableCell>
            <TableCell
              className={isValidRole(userData["Role"]) ? "" : classes.blankCell}
            >
              {userData["Role"]}
            </TableCell>
            <TableCell
              className={
                isValidReportingManagerMobileNumber(
                  userData["Reporting Manager Mobile Number"],
                  userData["Role"],
                  userData
                )
                  ? ""
                  : classes.blankCell
              }
            >
              {userData["Reporting Manager Mobile Number"]}
            </TableCell>
            <TableCell
              className={
                isValidPartnerName(userData["Partner Name"])
                  ? ""
                  : classes.blankCell
              }
            >
              {userData["Partner Name"]}
            </TableCell>
            <TableCell
              className={
                isValidZoneContent(userData["Role"], userData)
                  ? ""
                  : classes.blankCell
              }
            >
              {userData["Zone (Mandatory for ZBM, RBM,TBM,KA)"]}
            </TableCell>
            <TableCell
              className={
                isValidStateContent(
                  userData["State (Mandatory for KA,RBM,TBM)"],
                  userData
                )
                  ? ""
                  : classes.blankCell
              }
            >
              {userData["State (Mandatory for KA,RBM,TBM)"]}
            </TableCell>
            <TableCell
              className={
                isValidDistrictContent(
                  userData["District (Mandatory for KA)"],
                  userData
                )
                  ? ""
                  : classes.blankCell
              }
            >
              {userData["District (Mandatory for KA)"]}
            </TableCell>
            <TableCell
              className={
                isValidTerritoryContent(
                  userData["Territory (Mandatory for TBM,KA)"],
                  userData
                )
                  ? ""
                  : classes.blankCell
              }
            >
              {userData["Territory (Mandatory for TBM,KA)"]}
            </TableCell>
            <TableCell
              className={
                handleJoinedDate(userData["Joining Date (DD-MM-YYYY)"]) !==
                  "" &&
                handleJoinedDate(userData["Joining Date (DD-MM-YYYY)"]) !==
                  "Invalid Date"
                  ? ""
                  : classes.blankCell
              }
            >
              {handleJoinedDate(userData["Joining Date (DD-MM-YYYY)"])}
            </TableCell>
            <TableCell
              className={
                isValidTestUser(
                  userData[
                    "Test User (Yes or No)(In case of empty by default No will be selected)"
                  ],
                  userData
                )
                  ? ""
                  : classes.blankCell
              }
            >
              {
                userData[
                  "Test User (Yes or No)(In case of empty by default No will be selected)"
                ]
              }
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    ) : null;

    setOriginalTableBody(originalTableBody);
  }, [
    fileData,
    partnersList,
    rolesOptions,
    rmOptions,
    mobileCheck,
    zoneOptions,
    checkTrMapOptions,
  ]);

  const importAwayTableBody = fileData ? (
    <TableBody>
      {unsatisfiedRows.map((userData, index) => (
        <TableRow key={index} className="border-0 text-nowrap">
          <TableCell
            className={isNameValidate(userData.Name) ? "" : classes.blankCell}
          >
            {userData.Name}
          </TableCell>
          <TableCell
            className={
              !isValidMobileNumber(
                userData["Mobile Number"],
                unsatisfiedRows,
                index
              ) ||
              (Array?.isArray(mobileCheck) &&
                mobileCheck?.some(
                  (check) => check?.mobile === `${userData["Mobile Number"]}`
                ))
                ? classes.blankCell
                : ""
            }
          >
            {userData["Mobile Number"]}
          </TableCell>
          <TableCell
            className={isValidRole(userData["Role"]) ? "" : classes.blankCell}
          >
            {userData["Role"]}
          </TableCell>
          <TableCell
            className={
              isValidReportingManagerMobileNumber(
                userData["Reporting Manager Mobile Number"],
                userData["Role"],
                userData
              )
                ? ""
                : classes.blankCell
            }
          >
            {userData["Reporting Manager Mobile Number"]}
          </TableCell>
          <TableCell
            className={
              isValidPartnerName(userData["Partner Name"])
                ? ""
                : classes.blankCell
            }
          >
            {userData["Partner Name"]}
          </TableCell>
          <TableCell
            className={
              isValidZoneContent(userData["Role"], userData)
                ? ""
                : classes.blankCell
            }
          >
            {userData["Zone (Mandatory for ZBM, RBM,TBM,KA)"]}
          </TableCell>

          <TableCell
            className={
              isValidStateContent(
                userData["State (Mandatory for KA,RBM,TBM)"],
                userData
              )
                ? ""
                : classes.blankCell
            }
          >
            {userData["State (Mandatory for KA,RBM,TBM)"]}
          </TableCell>

          <TableCell
            className={
              isValidDistrictContent(
                userData["District (Mandatory for KA)"],
                userData
              )
                ? ""
                : classes.blankCell
            }
          >
            {userData["District (Mandatory for KA)"]}
          </TableCell>

          <TableCell
            className={
              isValidTerritoryContent(
                userData["Territory (Mandatory for TBM,KA)"],
                userData
              )
                ? ""
                : classes.blankCell
            }
          >
            {userData["Territory (Mandatory for TBM,KA)"]}
          </TableCell>

          <TableCell
            className={
              handleJoinedDate(userData["Joining Date (DD-MM-YYYY)"]) !== "" &&
              handleJoinedDate(userData["Joining Date (DD-MM-YYYY)"]) !==
                "Invalid Date"
                ? ""
                : classes.blankCell
            }
          >
            {handleJoinedDate(userData["Joining Date (DD-MM-YYYY)"])}
          </TableCell>
          <TableCell
            className={
              isValidTestUser(
                userData[
                  "Test User (Yes or No)(In case of empty by default No will be selected)"
                ],
                userData
              )
                ? ""
                : classes.blankCell
            }
          >
            {
              userData[
                "Test User (Yes or No)(In case of empty by default No will be selected)"
              ]
            }
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  ) : null;

  // const importAwayTableBody = (
  //   <TableBody>
  //     {unsatisfiedRows.map((userData, index) => (
  //       <TableRow key={index} className="border-0 text-nowrap">
  //         <TableCell>{userData.Name}</TableCell>
  //         <TableCell>{userData["Mobile Number"]}</TableCell>
  //         <TableCell>{userData["Role"]}</TableCell>
  //         <TableCell>{userData["Reporting Manager Mobile Number"]}</TableCell>
  //         <TableCell>{userData["Partner Name"]}</TableCell>
  //         <TableCell>
  //           {userData["Zone (Mandatory for ZBM, RBM,TBM,KA)"]}
  //         </TableCell>
  //         <TableCell>{userData["State (Mandatory for KA,RBM,TBM)"]}</TableCell>
  //         <TableCell>{userData["District (Mandatory for KA)"]}</TableCell>
  //         <TableCell>{userData["Territory (Mandatory for TBM,KA)"]}</TableCell>
  //         <TableCell>{handleJoinedDate(userData["Joining Date"])}</TableCell>
  //         <TableCell>{userData["Test User (Yes or No)"]}</TableCell>
  //       </TableRow>
  //     ))}
  //   </TableBody>
  // );
  const handleSwitchTestUser = (event) => {
    setImportAwayClicked(event.target.checked);
  };
  const downloadExcel = (workbook, fileName) => {
    try {
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    } catch (error) {
      console.error(error);
    }
  };

  const generateExcelFile = (rows) => {
    const headers = Object.keys(rows[0]);

    // Ensure the 'joinedDate' field is formatted as a date object or a string in a recognized date format
    const data = rows.map((row) => {
      // Assuming 'joinedDate' is the field name
      const formattedRow = { ...row };
      formattedRow["Joining Date (DD-MM-YYYY)"] = formatDate(
        formattedRow["Joining Date (DD-MM-YYYY)"]
      ); // Convert and format date
      return Object.values(formattedRow);
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    downloadExcel(wb, "BulkUpload_ErrorData");
  };

  // Function to format date as 'DD-MM-YYYY'
  const formatDate = (date) => {
    const Date = XLSX.SSF.parse_date_code(date);
    const jdate = Date?.d + "-" + Date?.m + "-" + Date?.y;

    return jdate;
  };

  return (
    <>
      {!dataLoading ? (
        <>
          <div className="container h-screen min-w-full overflow-hidden">
            <div className="block sm:flex items-center min-w-full">
              <ArrowBackIcon
                // onClick={closeDialog}
                onClick={() => {
                  if (isImportAwaayClick || fileUploaded) {
                    handleOpen();
                  } else {
                    closeDialog();
                  }
                }}
                className="cursor-pointer transition-all hover:text-[#009CA7] active:text-[#007a80] ml-5"
              />
              <h1 className="text-xl font-bold px-3 py-3">Bulk Upload</h1>
              <img
                src={guide}
                className="ml-auto mr-12 cursor-pointer"
                onClick={toggleGuide}
                alt="guide"
              />
              <GuideLines isOpen={isModalOpen} onClose={toggleGuide} />
            </div>
            <hr className="mt-0 border-gray-200" />
            <div className="container h-screen min-w-full ">
              <div className="flex items-center justify-center mt-6">
                <div
                  className={`w-6 h-6 rounded-full flex items-center justify-center mr-0 bg-[#009CA7]`}
                >
                  <span className="text-white">
                    {nextButnClicked ? (
                      <svg
                        width="12"
                        height="9"
                        viewBox="0 0 12 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2074 0.333344L4.16659 6.19823L1.79242 3.90211L0.499919 5.15697L4.16659 8.71684L11.4999 1.5971L10.2074 0.333344Z"
                          fill="white"
                        />
                      </svg>
                    ) : (
                      "1"
                    )}
                  </span>
                </div>

                <div className="h-1 relative bg-gray-200 w-36">
                  <div
                    className={`absolute inset-y-0 ${
                      fileUploaded ? "bg-green-500" : "bg-transparent"
                    }`}
                    style={{
                      width: nextButnClicked
                        ? "100%"
                        : fileUploaded
                        ? "50%"
                        : "0%",
                      backgroundColor: fileUploaded
                        ? "#009CA7"
                        : "bg-transparent",
                    }}
                  />
                </div>
                <div
                  className={`w-6 h-6 rounded-full flex items-center justify-center ml-0 ${
                    nextButnClicked ? "bg-[#009CA7]" : "bg-gray-200"
                  }`}
                >
                  <span
                    className={
                      nextButnClicked ? "text-white" : "text-[#838383]"
                    }
                  >
                    2
                  </span>
                </div>
              </div>
              <div className="flex justify-center mt-2">
                <label className="mr-32">Upload</label>
                <label
                  className={` mr-0 ${
                    fileUploaded ? "text-[#000000]" : "text-[#838383]"
                  }`}
                >
                  View
                </label>
              </div>
              <br />
              {nextButnClicked ? (
                <div className="h-[450px] overflow-auto min-w-full ">
                  <div className="block sm:flex items-center min-w-full">
                    <h1 className="text-sm ml-7 mr-auto mt-4">
                      Showing{" "}
                      {isImportAwaayClick
                        ? unsatisfiedRows?.length
                        : fileData
                        ? fileData?.length
                        : 0}{" "}
                      entries
                    </h1>
                    {unsatisfiedRows?.length != 0 &&
                      filteredRows?.length != 0 && (
                        <FormControlLabel
                          className="text-xs  mr-auto mt-4 "
                          control={
                            <Switch
                              className="-mr-2"
                              color="primary"
                              checked={isImportAwaayClick}
                              onChange={handleSwitchTestUser}
                            />
                          }
                          label={
                            <span
                              style={{ fontSize: "14px", color: "#1A1A1A" }}
                            >
                              Only show rows with errors
                            </span>
                          }
                        />
                      )}
                    {/* {!noError && (
                      <p className="ml-auto mr-8 cursor-pointer text-sm mt-4 ">
                        <div className="flex flex-row gap-2">
                          <svg
                            width="17"
                            height="18"
                            viewBox="0 0 17 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.66663 13.1667H9.33329V8.16669H7.66663V13.1667ZM8.49996 6.50002C8.73607 6.50002 8.93413 6.42002 9.09413 6.26002C9.25413 6.10002 9.33385 5.90224 9.33329 5.66669C9.33329 5.43058 9.25329 5.23252 9.09329 5.07252C8.93329 4.91252 8.73551 4.8328 8.49996 4.83335C8.26385 4.83335 8.06579 4.91335 7.90579 5.07335C7.74579 5.23335 7.66607 5.43113 7.66663 5.66669C7.66663 5.9028 7.74663 6.10085 7.90663 6.26085C8.06663 6.42085 8.2644 6.50058 8.49996 6.50002ZM8.49996 17.3334C7.34718 17.3334 6.26385 17.1145 5.24996 16.6767C4.23607 16.2389 3.35413 15.6453 2.60413 14.8959C1.85413 14.1459 1.26051 13.2639 0.823293 12.25C0.386071 11.2361 0.167182 10.1528 0.166626 9.00002C0.166626 7.84724 0.385515 6.76391 0.823293 5.75002C1.26107 4.73613 1.85468 3.85419 2.60413 3.10419C3.35413 2.35419 4.23607 1.76058 5.24996 1.32335C6.26385 0.886132 7.34718 0.667243 8.49996 0.666687C9.65274 0.666687 10.7361 0.885576 11.75 1.32335C12.7638 1.76113 13.6458 2.35474 14.3958 3.10419C15.1458 3.85419 15.7397 4.73613 16.1775 5.75002C16.6152 6.76391 16.8338 7.84724 16.8333 9.00002C16.8333 10.1528 16.6144 11.2361 16.1766 12.25C15.7388 13.2639 15.1452 14.1459 14.3958 14.8959C13.6458 15.6459 12.7638 16.2397 11.75 16.6775C10.7361 17.1153 9.65274 17.3339 8.49996 17.3334ZM8.49996 15.6667C10.3611 15.6667 11.9375 15.0209 13.2291 13.7292C14.5208 12.4375 15.1666 10.8611 15.1666 9.00002C15.1666 7.13891 14.5208 5.56252 13.2291 4.27085C11.9375 2.97919 10.3611 2.33335 8.49996 2.33335C6.63885 2.33335 5.06246 2.97919 3.77079 4.27085C2.47913 5.56252 1.83329 7.13891 1.83329 9.00002C1.83329 10.8611 2.47913 12.4375 3.77079 13.7292C5.06246 15.0209 6.63885 15.6667 8.49996 15.6667Z"
                              fill="#FF0000"
                            />
                          </svg>
                          <p>
                            If you import file with errors, the entries with
                            errors will not be reflected in the list.
                          </p>
                        </div>
                      </p>
                    )} */}
                  </div>
                  <div className="mr-5 ml-5">
                    <div className="container  py-1 m-2  mt-4  border-white bg-white  min-w-full">
                      <div className="flex items-center mt-2 ml-2">
                        {filteredRows?.length !== 0 && nextButnClicked && (
                          <div className={`flex items-center`}>
                            <div
                              className={`w-5 h-5 rounded-full bg-[#009CA7] flex items-center justify-center ml-3 mr-1`}
                            >
                              <svg
                                width="12"
                                height="9"
                                viewBox="0 0 12 9"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.2074 0.333344L4.16659 6.19823L1.79242 3.90211L0.499919 5.15697L4.16659 8.71684L11.4999 1.5971L10.2074 0.333344Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <span className="font-bold font-Open Sans size-14px">
                              <span className="text-[#009CA7] font-bold">
                                {filteredRows?.length}{" "}
                                {filteredRows?.length == 1
                                  ? "Entry"
                                  : "Entries"}
                              </span>{" "}
                              {filteredRows?.length == 1 ? "is" : "are"} ready
                              to be imported
                            </span>
                          </div>
                        )}
                        {(satisfiedRows?.length !== 0 &&
                          unsatisfiedRows?.length !== 0) ||
                          (satisfiedRows?.length === 0 &&
                            unsatisfiedRows?.length !== 0 && (
                              <div className={`flex items-center ml-4`}>
                                {filteredRows?.length !== 0 &&
                                  unsatisfiedRows?.length !== 0 && (
                                    <svg
                                      className={` mr-4`}
                                      width="1"
                                      height="23"
                                      viewBox="0 0 1 23"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <line
                                        x1="0.5"
                                        y1="2.18558e-08"
                                        x2="0.499999"
                                        y2="23"
                                        stroke="#CCCCCC"
                                      />
                                    </svg>
                                  )}

                                <svg
                                  className={` mr-1`}
                                  width="22"
                                  height="22"
                                  viewBox="0 0 22 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clipPath="url(#clip0_10412_3513)">
                                    <path
                                      d="M0.916748 19.25H21.0834L11.0001 1.83331L0.916748 19.25ZM11.9167 16.5H10.0834V14.6666H11.9167V16.5ZM11.9167 12.8333H10.0834V9.16665H11.9167V12.8333Z"
                                      fill="#FF0000"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_10412_3513">
                                      <rect
                                        width="22"
                                        height="22"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>

                                <span className="font-bold  font-Open Sans size-14px">
                                  <span className="text-[#FF0000] font-bold">
                                    {unsatisfiedRows?.length}{" "}
                                    {unsatisfiedRows?.length == 1
                                      ? "Entry"
                                      : "Entries"}
                                  </span>{" "}
                                  {filteredRows?.length === 0 &&
                                  unsatisfiedRows?.length !== 0
                                    ? "contains error, fix error before you import."
                                    : "will be skipped if you import."}
                                  {/* {filteredRows?.length !== 0 &&
                                    unsatisfiedRows?.length !== 0 && (
                                      <span
                                        className="text-[#009CA7] px-1 cursor-pointer underline"
                                        onClick={() => {
                                          generateExcelFile(unsatisfiedRows);
                                        }}
                                      >
                                        Download
                                      </span>
                                    )} */}
                                </span>
                              </div>
                            ))}
                      </div>
                      {fileData?.length !== 0 && (
                        <div className="border border-gray-50 min-w-full mt-2 "></div>
                      )}
                      <div className="m-3 mr-5 ml-5 mt-3 border-2 border-gray-300 ">
                        <hr className=" border-gray-200" />
                        <TableContainer component={Paper}>
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow className="bg-[#E5F5F6] text-nowrap">
                                <TableCell className={classes.bold}>
                                  Name
                                </TableCell>
                                <TableCell className={classes.bold}>
                                  Mobile No
                                </TableCell>
                                <TableCell className={classes.bold}>
                                  Role
                                </TableCell>
                                <TableCell className={classes.bold}>
                                  Reporting Manager Mobile Number
                                </TableCell>
                                <TableCell className={classes.bold}>
                                  Partner Name
                                </TableCell>
                                <TableCell className={classes.bold}>
                                  Zone
                                </TableCell>
                                <TableCell className={classes.bold}>
                                  State
                                </TableCell>
                                <TableCell className={classes.bold}>
                                  District
                                </TableCell>
                                <TableCell className={classes.bold}>
                                  Territory
                                </TableCell>
                                <TableCell className={classes.bold}>
                                  Joining Date
                                </TableCell>
                                <TableCell className={classes.bold}>
                                  Test User
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            {isImportAwaayClick
                              ? importAwayTableBody
                              : originalTableBody}
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </div>
                </div>
              ) : fileUploaded ? (
                <div className="mt-10">
                  <div className="flex justify-center mt-0">
                    <div className="border-2 border-dashed border-opacity-50 border-gray-400 bg-[#D3FFD8] flex flex-col justify-center items-center p-10">
                      <input
                        ref={fileInputRef}
                        type="file"
                        id="fileInput"
                        accept=".csv, .xlsx"
                        style={{ display: "none" }}
                        onChange={handleFileUpload}
                      />
                      {fileName && (
                        <h2>
                          {fileName}{" "}
                          <span
                            className="cursor-pointer text-2xl text-red-500 ml-2"
                            onClick={handleRemoveFile}
                          >
                            x
                          </span>
                        </h2>
                      )}
                      <h1
                        onClick={() => {
                          const fileInput = document.getElementById(
                            "fileInput"
                          ) as HTMLInputElement;
                          if (fileInput) {
                            const currentFile = fileInput.files
                              ? fileInput.files[0]
                              : null;
                            const currentFileName = currentFile
                              ? currentFile.name
                              : "";

                            fileInput.click();

                            fileInput.addEventListener("change", () => {
                              if (fileInput.files && fileInput.files[0]) {
                                handleRemoveFile();
                                setFile(fileInput.files[0]);
                                setFileName(fileInput.files[0].name);
                              } else {
                                setFile(currentFile);
                                setFileName(currentFileName);
                              }
                            });
                          }
                        }}
                        className="mt-4 cursor-pointer text-[#009CA7] font-semibold"
                      >
                        Replace file
                      </h1>
                    </div>
                  </div>
                  <div className="flex justify-center items-center mt-5">
                    <h1>
                      Use csv, xlsx format, or{" "}
                      <a
                        href="/Bulk upload template.xlsx"
                        className="text-[#009CA7] font-bold"
                        download
                      >
                        Download Template
                      </a>
                    </h1>
                  </div>
                </div>
              ) : (
                <div className="mt-10">
                  <div className="flex justify-center mt-0">
                    <div
                      className="border-2 border-dashed border-opacity-50 border-gray-300 bg-white flex flex-col justify-center items-center py-10 px-28"
                      onClick={() =>
                        document.getElementById("fileInput")?.click()
                      }
                    >
                      <input
                        type="file"
                        id="fileInput"
                        accept=".csv, .xlsx"
                        style={{ display: "none" }}
                        onChange={handleFileUpload}
                      />
                      <img src={upload} alt="Bulk Upload Image" />
                      <h1 className="mt-4" style={{ color: "#009CA7" }}>
                        Upload file
                      </h1>
                    </div>
                  </div>
                  <div className="flex flex-div justify-center items-center mt-5">
                    <h1>
                      Use csv, xlsx format, or{" "}
                      <a
                        href="/Bulk upload template.xlsx"
                        download
                        className="text-[#009CA7] font-bold"
                      >
                        Download Template
                      </a>
                    </h1>
                  </div>
                </div>
              )}
            </div>
            <footer className="fixed bottom-0 flex justify-center items-center min-w-full bg-white py-1.5">
              {(fileData && !nextButnClicked) ||
              (!fileUploaded && !nextButnClicked) ||
              (fileUploaded && !nextButnClicked) ? (
                <div className="flex justify-center items-center  gap-96">
                  <button
                    className="flex px-6 py-1.5 border-2 border-grey-300 rounded-md bg-white -ml-24"
                    onClick={() => {
                      if (isImportAwaayClick || fileUploaded) {
                        handleOpen();
                      } else {
                        closeDialog();
                      }
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className={`flex px-8 -mr-7  py-1.5 border-2 border-grey-300 rounded-md text-white ${
                      fileUploaded && file != null
                        ? `bg-[#009CA7]`
                        : `bg-[#B3B3B3]`
                    }`}
                    disabled={!fileUploaded || file === null}
                    onClick={nextButtonClicked}
                  >
                    Next
                  </button>
                </div>
              ) : (
                <div className="flex justify-center items-center space-x-72">
                  <button
                    className="flex px-6 py-1.5 border-2 border-grey-300 rounded-md bg-white"
                    onClick={() => {
                      if (isImportAwaayClick || fileUploaded) {
                        handleOpen();
                      } else {
                        closeDialog();
                      }
                    }}
                  >
                    Cancel
                  </button>
                  <div className="flex gap-3">
                    {nextButnClicked || isImportAwaayClick ? (
                      <button
                        className="flex px-6 py-1.5 border-2 border-grey-300 rounded-md bg-white"
                        onClick={handlePrevClick}
                      >
                        Previous
                      </button>
                    ) : null}
                    {nextButnClicked ? (
                      <button
                        onClick={handleCreateUser}
                        disabled={
                          (filteredRows?.length === 0 &&
                            unsatisfiedRows?.length !== 0) ||
                          fileData?.length == 0 ||
                          dataImporting
                        }
                        className={`flex px-6 py-1.5 border-2 border-grey-300 rounded-md ${
                          (filteredRows?.length === 0 &&
                            unsatisfiedRows?.length !== 0) ||
                          fileData?.length == 0 ||
                          dataImporting
                            ? "bg-[#CED4DA]"
                            : "bg-[#009CA7]"
                        } text-white`}
                      >
                        Import
                      </button>
                    ) : null}

                    {/* // (!noError || filteredRows?.length === 0) && (
                      //   // <button
                      //   //   className={`flex px-6 py-1.5 border-2 border-grey-300 rounded-md ${
                      //   //     filteredRows?.length === 0
                      //   //       ? `bg-[#CED4DA]`
                      //   //       : `bg-[#009CA7]`
                      //   //   } text-white`}
                      //   //   onClick={isImportAwayClicked}
                      //   //   disabled={filteredRows?.length === 0}
                      //   // >
                      //   //   Import
                      //   // </button>
                      // )
                    )} */}
                  </div>
                </div>
              )}
            </footer>
            <AlertDialog
              closeDialog={closeDialog}
              handleClose={handleClose}
              open={open}
            />
          </div>
        </>
      ) : (
        <>
          <ReactLoadingOverlay
            classNamePrefix="growindigo_"
            active={true}
            spinner={<ClipLoader color="#27878E" />}
            text={""}
          >
            {/* Your content here */}
          </ReactLoadingOverlay>
        </>
      )}
    </>
  );
};

export default BulkUpload;
