import React, { useState, useEffect, useContext } from "react";
import "react-toastify/dist/ReactToastify.css";
import { MultiSelect } from "react-multi-select-component";
import { FnAuthenticatedRequest } from "../../Utility/Utils";
import StorageHelper from "../../Utility/StorageHelper";
import ReactLoadingOverlay from "react-loading-overlay";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";
import { MsalContext } from "@azure/msal-react";
import { APP_ROUTES } from "../../Libraries/AppConstants";

interface AddUserLocationDetailsProps {
  setLocations: (
    arrZones: any,
    arrStates: any,
    arrTerritories: any,
    arrDistricts: any
  ) => void;
  selectedRole: string;
  fromEditUser: boolean;
  territoryData: any;
  rolesData: any;
}
interface Option {
  label: string;
  value: string;
}

const Adduserlocationdetails: React.FC<AddUserLocationDetailsProps> = ({
  setLocations,
  selectedRole,
  fromEditUser,
  territoryData,
  rolesData,
}) => {
  const [zoneOptions, setZoneOptions] = useState<Option[]>([]);
  const [selectedZones, setSelectedzones] = useState<Option[]>([]);

  const [stateOptions, setStateOptions] = useState<Option[]>([]);
  const [selectedStates, setSelectedStates] = useState<Option[]>([]);

  const [territoryOptions, setTerritoryOptions] = useState<Option[]>([]);
  const [selectedTerritories, setSelectedTerritories] = useState<Option[]>([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [districtOptions, setDistrictOptions] = useState<Option[]>([]);
  const [selectedDistricts, setSelectedDistricts] = useState<Option[]>([]);
  const [datatrloading, setTrDataLoading] = useState(false);
  const [datadiloading, setDiDataLoading] = useState(false);
  const [datastateloading, setStateDataLoading] = useState(false);

  useEffect(() => {
    if (
      selectedRole === "ZBM" ||
      selectedRole === "RBM" ||
      selectedRole === "TBM" ||
      selectedRole === "KA"
    )
      fetchDataFromApi();
  }, [selectedRole]);

  const history = useHistory();
  const msalContext = useContext(MsalContext);
  const fetchDataFromApi = async () => {
    try {
      setDataLoading(true);
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken:  authData.jwtToken,
          userId: authData.userId,
        };
        const response: any = await FnAuthenticatedRequest(
          "POST",
          "api/GetZoneDetails",
          authHeader,
          ""
        );
        if (response?.success) {
          const formattedData = response?.data?.map((zone) => ({
            label: zone.zoneName?.toUpperCase(),
            value: zone.zoneId,
            zoneId: zone.zoneId,
            zoneName: zone.zoneName?.toUpperCase(),
          }));
          setDataLoading(false);
          setZoneOptions(formattedData);
        }
      }
    } catch (error: any) {
      setDataLoading(false);
      console.error("Error fetching zone data:", error);
      if (error.response && error.response.status) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          StorageHelper.removeAll();
          const msalInstance = msalContext.instance;
          // msalInstance.logoutPopup();
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          history.replace(APP_ROUTES.LOGIN);
        } else {
          console.error(error);
        }
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    setSelectedzones([]);
    setSelectedStates([]);
    setSelectedTerritories([]);
    setSelectedDistricts([]);
    setStateOptions([]);
    setTerritoryOptions([]);
    setDistrictOptions([]);
  }, [selectedRole]);

  useEffect(() => {
    if (
      selectedZones?.length > 0 ||
      // selectedRole === "KA" ||
      selectedRole === "TBM" ||
      (selectedRole !== "ZBM" && selectedZones?.length > 0) ||
      (selectedRole === "RBM" && selectedZones?.length > 0)
    ) {
      fetchZoneWiseState(selectedZones.map((zone) => zone.value) || [""]);
    }
  }, [selectedZones, selectedRole]);

  useEffect(() => {
    if (selectedStates?.length > 0 && selectedRole !== "ZBM") {
      fetchStateWiseTeritaory(selectedStates.map((state) => state.value));
    }
  }, [selectedStates]);

  useEffect(() => {
    if (selectedTerritories?.length > 0 && selectedRole === "KA") {
      fetchDistricts(selectedTerritories.map((state) => state.value));
    }
  }, [selectedTerritories]);

  useEffect(() => {
    const zoneIds = selectedZones.map((zone: any) => zone.value);
    const filteredStates = selectedStates.filter((state: any) =>
      zoneIds.includes(state.zoneId)
    );
    if (
      (selectedRole === "RBM" ||
        selectedRole === "TBM" ||
        selectedRole === "KA") &&
      zoneIds?.length === 0
    ) {
      setStateOptions([]);
      setTerritoryOptions([]);
      setDistrictOptions([]);
      setSelectedStates([]);
      setSelectedTerritories([]);
      setSelectedDistricts([]);
    }
    setSelectedStates(filteredStates);
  }, [selectedZones]);

  useEffect(() => {
    const stateIds = selectedStates.map((state: any) => state.value);
    const filteredTerritories = selectedTerritories.filter((territory: any) =>
      stateIds.includes(territory.stateId)
    );
    if (
      (selectedRole === "RBM" ||
        selectedRole === "TBM" ||
        selectedRole === "KA") &&
      stateIds?.length === 0
    ) {
      setTerritoryOptions([]);
      setDistrictOptions([]);
      setSelectedTerritories([]);
      setSelectedDistricts([]);
    }
    setSelectedTerritories(filteredTerritories);
  }, [selectedStates]);

  useEffect(() => {
    const territoryIds = selectedTerritories.map(
      (territory: any) => territory.value
    );
    const filteredDistricts = selectedDistricts.filter((district: any) =>
      territoryIds.includes(district.territoryId)
    );
    if (selectedRole === "KA" && territoryIds?.length === 0) {
      setDistrictOptions([]);
      setSelectedDistricts([]);
    }
    setSelectedDistricts(filteredDistricts);
  }, [selectedTerritories]);

  const fetchZoneWiseState = async (arrZoneIds: string[]) => {
    try {
      setStateDataLoading(true);
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken: authData.jwtToken,
          userId: authData.userId,
        };
        const payload = {
          zone_id: arrZoneIds,
        };
        const response: any =
          arrZoneIds?.length > 0 &&
          (await FnAuthenticatedRequest(
            "POST",
            "api/GetZoneWiseState",
            authHeader,
            payload
          ));

        if (response?.success) {
          const data: any = response?.data;

          let stateOptions = data?.map((state) => {
            let stateObject = {
              label: state.stateName?.toUpperCase(),
              value: state.stateId,
              stateId: state.stateId,
              stateName: state.stateName?.toUpperCase(),
              zoneId: state.zoneId,
            };
            return stateObject;
          });
          if (arrZoneIds?.length > 0) {
            setStateOptions(stateOptions);
            setStateDataLoading(false);
          }
        } else {
          setStateDataLoading(false);
          console.error("Error fetching states:", response.data);
        }
      }
    } catch (error: any) {
      setStateDataLoading(false);
      console.error("Error fetching  states:", error);
      if (error?.response && error?.response.status) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          StorageHelper.removeAll();
          const msalInstance = msalContext.instance;
          // msalInstance.logoutPopup();
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          history.replace(APP_ROUTES?.LOGIN);
        } else {
          console?.error("Error fetching  states:", error);
        }
      } else {
        console?.error("Error fetching  states:", error);
      }
    }
  };

  const fetchStateWiseTeritaory = async (arrStateIds: string[]) => {
    try {
      setTrDataLoading(true);
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      if (authData) {
        const authHeader = {
          JWTToken:  authData.jwtToken,
          userId: authData.userId,
        };
        const response: any = await FnAuthenticatedRequest(
          "POST",
          "api/GetTerritoryDetails",
          authHeader,
          arrStateIds
        );

        if (response?.success) {
          setTrDataLoading(false);
          let territoryOptions: any = [];
          response?.data?.forEach((tr) => {
            let trObject = {
              label: tr.territoryName?.toUpperCase(),
              value: tr.territoryId,
              trId: tr.territoryId,
              territoryName: tr.territoryName?.toUpperCase(),
              stateId: tr.stateId,
            };
            territoryOptions.push(trObject);
            if (arrStateIds?.length > 0) {
              setTerritoryOptions(territoryOptions);
            }
          });
        } else {
          setTrDataLoading(false);
          console.error("Error fetching data :", response.data);
        }
      }
    } catch (error: any) {
      setTrDataLoading(false);
      console.error("Error fetching  data:", error);
      if (error.response && error.response.status) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          StorageHelper.removeAll();
          const msalInstance = msalContext.instance;
          // msalInstance.logoutPopup();
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          history.replace(APP_ROUTES.LOGIN);
        } else {
          console.error("Error fetching  data:", error);
        }
      } else {
        console.error("Error fetching  data:", error);
      }
    }
  };

  const fetchDistricts = async (arrTrIds: string[]) => {
    try {
      setDiDataLoading(true);
      let authData = JSON.parse(StorageHelper.get("auth") || "{}");
      const authHeader = {
        JWTToken:  authData.jwtToken,
        userId: authData.userId,
      };
      const response: any = await FnAuthenticatedRequest(
        "POST",
        "api/TerritoryWiseDistrict",
        authHeader,
        arrTrIds
      );

      if (response?.success) {
        setDiDataLoading(false);
        const data: any = response?.data;

        let districtOptions = data?.map((district) => {
          let districtObject = {
            label: district.districtName?.toUpperCase(),
            value: district.districtId,
            districtId: district.districtId,
            districtName: district.districtName?.toUpperCase(),
            stateId: district.stateId,
            territoryId: district.territoryId,
          };
          return districtObject;
        });
        if (arrTrIds?.length > 0) {
          setDistrictOptions(districtOptions);
        }
      } else {
        setDiDataLoading(false);
        console.error("Error fetching districts:", response.data);
      }
    } catch (error: any) {
      setDiDataLoading(false);
      console.error("Error fetching  districts:", error);
      if (error.response && error.response.status) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          StorageHelper.removeAll();
          const msalInstance = msalContext.instance;
          // msalInstance.logoutPopup();
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });

          history.replace(APP_ROUTES.LOGIN);
        } else {
          console.error("Error fetching  districts:", error);
        }
      } else {
        console.error("Error fetching  districts:", error);
      }
    }
  };

  useEffect(() => {
    const zone = selectedZones?.map((zone: any) => {
      let zoneObject = {
        zoneId: zone?.value,
        zoneName: zone?.label,
      };
      return zoneObject;
    });

    const st = selectedStates?.map((state: any) => {
      let stateObject = {
        stateId: state?.value,
        stateName: state?.label,
        zoneId: state?.zoneId,
      };
      return stateObject;
    });

    const tr = selectedTerritories?.map((tr: any) => {
      let trObject = {
        territoryId: tr?.value,
        territoryName: tr?.label,
        stateId: tr?.stateId,
      };
      return trObject;
    });

    const dt = selectedDistricts?.map((district: any) => {
      let districtObject = {
        districtId: district?.value,
        districtName: district?.label,
        territoryId: district?.territoryId,
        stateId: district?.stateId,
      };

      return districtObject;
    });

    setLocations(zone, st, tr, dt);
  }, [selectedStates, selectedDistricts, selectedZones, selectedTerritories]);

  useEffect(() => {
    if (selectedDistricts?.length && districtOptions?.length) {
      const filteredSelectedDistricts = selectedDistricts?.filter((district) =>
        districtOptions?.some((option) => option?.value === district?.value)
      );
      setSelectedDistricts(filteredSelectedDistricts);
    }
  }, [districtOptions]);

  const customMessages = {
    allItemsAreSelected: "All Selected",
    selectSomeItems: "All",
    selectAllFiltered: "All",
  };

  const customDisplayValueZones = () => {
    if (selectedZones?.length === 0) {
      return "Not Selected";
    } else if (selectedZones?.length === zoneOptions?.length) {
      return `All selected`;
    } else {
      return `${selectedZones?.length} selected`;
    }
  };

  const customDisplayValueTr = () => {
    if (selectedTerritories?.length === 0) {
      return "Not Selected";
    } else if (selectedTerritories?.length === territoryOptions?.length) {
      return `All selected`;
    } else {
      return `${selectedTerritories?.length} selected`;
    }
  };
  const customDisplayValueStates = () => {
    if (selectedStates?.length === 0) {
      return "Not Selected";
    } else if (selectedStates?.length === stateOptions?.length) {
      return `All selected`;
    } else {
      return `${selectedStates?.length} selected`;
    }
  };

  const customDisplayValueDistrict = () => {
    if (selectedDistricts?.length === 0) {
      return "Not Selected";
    } else if (selectedDistricts?.length === districtOptions?.length) {
      return `All selected`;
    } else {
      return `${selectedDistricts?.length} selected`;
    }
  };
  useEffect(() => {
    if (fromEditUser) {
      if (territoryData?.zone?.length) {
        setDataLoading(true);
        const slZone = territoryData?.zone?.map((zone) => {
          return {
            label: zone?.zoneName,
            value: zone?.zoneId,
          };
        });
        if (rolesData[0].roleName === selectedRole) {
          setSelectedzones(slZone);
          setDataLoading(false);
        }
      }

      if (territoryData?.state?.length) {
        setDataLoading(true);
        const stateData = territoryData?.state.map((state) => {
          return {
            label: state?.stateName,
            value: state?.stateId,
            stateId: state?.stateId,
            stateName: state?.stateName,
            zoneId: state?.zoneId,
          };
        });
        setSelectedStates(stateData);
        if (stateData) {
          setDataLoading(false);
        }
      }

      if (territoryData?.territory?.length) {
        setDataLoading(true);

        const trData = territoryData?.territory?.map((territory) => {
          return {
            label: territory?.territoryName,
            value: territory?.territoryId,
            stateId: territory?.stateId,
          };
        });

        setSelectedTerritories(trData);
        if (trData) {
          setDataLoading(false);
        }
      }

      if (territoryData?.district?.length) {
        setDataLoading(true);

        const districtData = territoryData?.district?.map((district) => {
          return {
            label: district?.districtName,
            value: district?.districtId,
            territoryId: district?.territoryId,
            stateId: district?.stateId,
          };
        });

        setSelectedDistricts(districtData);
        if (districtData) {
          setDataLoading(false);
        }
      }
    }
  }, [fromEditUser, rolesData, territoryData, selectedRole]);

  const handleZoneChange = (selectedOptions) => {
    const isExistingOption = selectedOptions?.some((option) =>
      selectedZones?.includes(option)
    );

    if (selectedOptions?.length > 1 && selectedRole !== "ZBM") {
      if (isExistingOption) {
        return;
      }

      setSelectedzones([selectedOptions[0]]);
    } else {
      setSelectedzones(selectedOptions);
    }
  };

  const handleStateChange = (selectedOptions) => {
    const isExistingOption = selectedOptions?.some((option) =>
      selectedStates.includes(option)
    );

    if (selectedOptions?.length > 1 && selectedRole !== "RBM") {
      if (isExistingOption) {
        return;
      }

      setSelectedStates([selectedOptions[0]]);
    } else {
      setSelectedStates(selectedOptions);
    }
  };

  const handleTerritoryChange = (selectedOptions) => {
    const isExistingOption = selectedOptions?.some((option) =>
      selectedTerritories?.includes(option)
    );

    if (selectedOptions?.length > 1 && selectedRole !== "TBM") {
      if (isExistingOption) {
        return;
      }

      setSelectedTerritories([selectedOptions[0]]);
    } else {
      setSelectedTerritories(selectedOptions);
    }
  };

  const handleDistrictChange = (selectedOptions) => {
    if (selectedOptions?.length > 1 && selectedRole !== "KA") {
      setSelectedDistricts([selectedOptions[0]]);
    } else {
      setSelectedDistricts(selectedOptions);
    }
  };

  return (
    <>
      <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
        {/* Zones */}
        {(selectedRole === "ZBM" ||
          selectedRole === "RBM" ||
          selectedRole === "TBM" ||
          selectedRole === "KA") && (
          <div>
            <label id="zones-add-user">
              Zone<span style={{ color: "red" }}>*</span>
            </label>
            {!dataLoading ? (
              <MultiSelect
                options={zoneOptions}
                value={selectedZones}
                onChange={handleZoneChange}
                labelledBy="zones-add-user"
                hasSelectAll={false}
                valueRenderer={customDisplayValueZones}
                overrideStrings={customMessages}
                shouldToggleOnHover={false}
              />
            ) : (
              <div style={{ position: "relative" }}>
                <MultiSelect
                  options={zoneOptions}
                  value={selectedZones}
                  onChange={handleZoneChange}
                  labelledBy="zones-add-user"
                  hasSelectAll={false}
                  valueRenderer={customDisplayValueZones}
                  overrideStrings={customMessages}
                  shouldToggleOnHover={false}
                  disabled
                  disableSearch={true}
                />
                {/* Loading spinner */}
                <ReactLoadingOverlay
                  classNamePrefix="growindigo_"
                  active={true}
                  spinner={<ClipLoader color="#27878E" />}
                  text={""}
                >
                  {/* Your content here */}
                </ReactLoadingOverlay>
              </div>
            )}
          </div>
        )}

        {/* State */}
        {(selectedRole === "RBM" ||
          selectedRole === "TBM" ||
          selectedRole === "KA") && (
          <div id="adduser-location">
            <label id="state-add-user">
              State<span style={{ color: "red" }}>*</span>
            </label>
            {!datastateloading ? (
              <MultiSelect
                options={stateOptions}
                value={selectedStates}
                onChange={handleStateChange}
                labelledBy="state-add-user"
                hasSelectAll={false}
                valueRenderer={customDisplayValueStates}
                overrideStrings={customMessages}
                shouldToggleOnHover={false}
              />
            ) : (
              <div style={{ position: "relative" }}>
                <MultiSelect
                  options={stateOptions}
                  value={selectedStates}
                  onChange={handleStateChange}
                  labelledBy="state-add-user"
                  hasSelectAll={false}
                  valueRenderer={customDisplayValueStates}
                  overrideStrings={customMessages}
                  shouldToggleOnHover={false}
                  disabled
                  disableSearch={true}
                />
                {/* Loading spinner */}
                <ReactLoadingOverlay
                  classNamePrefix="growindigo_"
                  active={true}
                  spinner={<ClipLoader color="#27878E" />}
                  text={""}
                >
                  {/* Your content here */}
                </ReactLoadingOverlay>
              </div>
            )}
          </div>
        )}

        {/* Territory */}
        {(selectedRole === "TBM" || selectedRole === "KA") && (
          <div>
            <label id="territory-add-user">
              Territory<span style={{ color: "red" }}>*</span>
            </label>
            {!datatrloading ? (
              <MultiSelect
                options={territoryOptions}
                value={selectedTerritories}
                onChange={handleTerritoryChange}
                labelledBy="territory-add-user"
                hasSelectAll={false}
                valueRenderer={customDisplayValueTr}
                overrideStrings={customMessages}
                shouldToggleOnHover={false}
              />
            ) : (
              <div style={{ position: "relative" }}>
                <MultiSelect
                  options={territoryOptions}
                  value={selectedTerritories}
                  onChange={handleTerritoryChange}
                  labelledBy="territory-add-user"
                  hasSelectAll={false}
                  valueRenderer={customDisplayValueTr}
                  overrideStrings={customMessages}
                  shouldToggleOnHover={false}
                  disabled
                  disableSearch={true}
                />
                {/* Loading spinner */}
                <ReactLoadingOverlay
                  classNamePrefix="growindigo_"
                  active={true}
                  spinner={<ClipLoader color="#27878E" />}
                  text={""}
                >
                  {/* Your content here */}
                </ReactLoadingOverlay>
              </div>
            )}
          </div>
        )}

        {/* District */}
        {selectedRole === "KA" && (
          <div>
            <label id="district-add-user">
              District<span style={{ color: "red" }}>*</span>
            </label>
            {!datastateloading ? (
              <MultiSelect
                options={districtOptions}
                value={selectedDistricts}
                onChange={handleDistrictChange}
                labelledBy="district-add-user"
                hasSelectAll={false}
                valueRenderer={customDisplayValueDistrict}
                overrideStrings={customMessages}
              />
            ) : (
              <div style={{ position: "relative" }}>
                <MultiSelect
                  options={districtOptions}
                  value={selectedDistricts}
                  onChange={handleDistrictChange}
                  labelledBy="district-add-user"
                  hasSelectAll={false}
                  valueRenderer={customDisplayValueDistrict}
                  overrideStrings={customMessages}
                  disabled
                  disableSearch={true}
                />
                {/* Loading spinner */}
                <ReactLoadingOverlay
                  classNamePrefix="growindigo_"
                  active={true}
                  spinner={<ClipLoader color="#27878E" />}
                  text={""}
                >
                  {/* Your content here */}
                </ReactLoadingOverlay>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Adduserlocationdetails;
