import React, { useState, useEffect } from "react";
import { Checkbox, Dialog, FormControlLabel } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import RoleMangerList from "./RoleMangerList";
import { toast } from "react-toastify";
import { FnAuthenticatedRequest } from "../../Utility/Utils";

export default function AssignModal({ open, handleCloseAssignModal, data }) {
  const [roleManagers, setRoleManagers] = useState([]);
  const [isSoilSamplingSelected, setIsSoilSamplingSelected] = useState(false);
  const [isCropCuttingSelected, setIsCropCuttingSelected] = useState(false);
  const [assignedManager, setAssignedManager] = useState(null);
  const [filteredRoleManagers, setFilteredRoleManagers] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const districts: string[] = [];
    data?.forEach((entry) => {
      if (!districts.includes(entry.districtId)) {
        districts.push(entry.districtId);
      }
    });

    if (districts?.length > 0) {
      handleFetchRoleManagers(districts);
    }
  }, [data]);

  const handleSubmit = async () => {
    try {
      const acitivityParams = data.map((farmer) => {
        let fieldActivityObject = {
          activityId: farmer.activityId.toString(),
          farmerId: farmer.farmerId,
          fieldId: farmer.fieldId,
          activityYear: farmer.activityYear,
          soilSampling:
            isSoilSamplingSelected === true ? assignedManager : null,
          cropCutting: isCropCuttingSelected === true ? assignedManager : null,
          isSampled: "Y",
        };
        return fieldActivityObject;
      });

      const authData = JSON.parse(localStorage.getItem("auth") || "{}");
      if (authData) {
        const authHeader = {
           JWTToken: authData.jwtToken,
          userId: authData.userId,
        };
        const payload = acitivityParams;
        const response: any = await FnAuthenticatedRequest(
          "POST",
          "api/updateFieldActivity",
          authHeader,
          payload
        );

        if (response?.success) {
          toast.success("Updated successfully", {
            position: toast.POSITION.TOP_CENTER,
            style: {
              backgroundColor: "#258750",
              color: "white",
            },
            autoClose: 3000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFetchRoleManagers = async (params) => {
    try {
      const authData = JSON.parse(localStorage.getItem("auth") || "{}");
      if (authData) {
        const authHeader = {
           JWTToken: authData.jwtToken,
          userId: authData.userId,
        };
        const payload = params;
        const response: any = await FnAuthenticatedRequest(
          "POST",
          "api/getRoleManagers",
          authHeader,
          payload
        );

        if (response?.success && response?.data?.length > 0) {
          setRoleManagers(response?.data);
          setFilteredRoleManagers(response?.data);
          return response?.data;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (searchInput) {
      handleSearchInput(roleManagers, searchInput);
    }
  }, [searchInput, roleManagers]);

  const filterUsers = (users, searchInput) => {
    const search = searchInput.toLowerCase();
    return users.filter(
      (user) =>
        (user?.name && user?.name.toLowerCase().includes(search)) ||
        (user?.mobile && user?.mobile.toString().includes(search))
    );
  };

  const handleSearchInput = (roleManager, input) => {
    try {
      setSearchInput(input);
      if (input?.length > 2) {
        const filteredManagers = filterUsers(roleManager, input);
        if (filterUsers?.length > 0) {
          setFilteredRoleManagers(filteredManagers);
        }
      } else {
        setFilteredRoleManagers(roleManager);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleCloseAssignModal();
          setAssignedManager(null);
          setIsCropCuttingSelected(false);
          setIsSoilSamplingSelected(false);
        }}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: {
            width: "50%",
            maxWidth: "none",
          },
        }}
      >
        <div className="p-3">
          <h3 className="flex justify-center items-center font-bold text-xl">
            Assign to (Year 2024)
            <span className="ml-auto">
              <IconButton
                aria-label="close"
                onClick={() => {
                  handleCloseAssignModal();
                  setAssignedManager(null);
                  setIsCropCuttingSelected(false);
                  setIsSoilSamplingSelected(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </h3>
        </div>
        <div className="px-3 text-sm">
          <p>Select activity</p>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                onChange={(e) => {
                  setIsSoilSamplingSelected((prevState) => !prevState);
                }}
              />
            }
            label="Soil Sampling"
            style={{
              color: "gray",
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                onChange={() =>
                  setIsCropCuttingSelected((prevState) => !prevState)
                }
              />
            }
            label="Crop Cutting"
            style={{
              color: "gray",
            }}
          />
        </div>
        <RoleMangerList
          roleManager={filteredRoleManagers}
          setAssignedManager={setAssignedManager}
          handleSearchInput={handleSearchInput}
        />
        <div className="flex gap-2 p-4">
          <button
            className={`border border-gray-300 px-4 py-1 rounded ${
              assignedManager === null ||
              assignedManager === "" ||
              (isSoilSamplingSelected === false &&
                isCropCuttingSelected === false)
                ? `bg-gray-300 text-white`
                : `bg-[#009CA7] text-white`
            } `}
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
